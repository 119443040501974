import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
};

export type Query = {
  __typename?: 'Query';
  questionnaire?: Maybe<Questionnaire>;
  questionnaires?: Maybe<QuestionnaireConnection>;
  questionnairesExport?: Maybe<Array<Maybe<Questionnaire>>>;
  questionnaireStatistics?: Maybe<Array<Maybe<QuestionnaireStatistic>>>;
  evaluationStatistics?: Maybe<Array<Maybe<QuestionnaireStatistic>>>;
  section?: Maybe<Section>;
  documents?: Maybe<Array<Maybe<Document>>>;
  threads?: Maybe<ThreadConnection>;
  unreadMessagesCount?: Maybe<Scalars['Int']>;
  procurementUserProfiles?: Maybe<Array<Maybe<ProcurementProvider>>>;
  sectionsExport?: Maybe<Questionnaire>;
  teams?: Maybe<Array<Maybe<TeTeam>>>;
  team?: Maybe<TeTeam>;
  clarificationQuestion?: Maybe<Array<Maybe<ClarificationQuestions>>>;
  clarificationQuestions?: Maybe<Array<Maybe<ClarificationQuestions>>>;
  specialisms?: Maybe<Array<Maybe<Specialisms>>>;
  serviceLocationMobilisations?: Maybe<Array<Maybe<ServiceLocationMobilisation>>>;
  languages?: Maybe<Array<Maybe<LanguageSelect>>>;
  lettersIssuedInfo?: Maybe<LettersIssuedInfo>;
  commissionersContactInformation?: Maybe<Array<Maybe<CommissionerContactInformation>>>;
  notices?: Maybe<Array<Maybe<NoticeRequired>>>;
  callDurations?: Maybe<Array<Maybe<NoticeRequired>>>;
  capacityUpdates?: Maybe<CapacityUpdateConnection>;
  mobilisation2021?: Maybe<LegalEntity2021>;
  staffMemberDc?: Maybe<StaffMemberDc>;
  dcVariationAgreementReturns?: Maybe<DcVariationAgreementReturnConnection>;
  allDcVariationAgreementReturns?: Maybe<Array<Maybe<DcVariationAgreementReturn>>>;
  vacancyContact?: Maybe<VacancyContact>;
  careHomes?: Maybe<Array<Maybe<CareHome>>>;
  providers?: Maybe<Array<Maybe<Provider>>>;
  domcareQuestionnaires?: Maybe<Array<Maybe<DomCareQuestionnaire>>>;
  filtersTracker?: Maybe<FiltersTrackerConnection>;
  directoryOfServicesLocation?: Maybe<DirectoryOfServicesLocation>;
  directoryOfServicesLocations?: Maybe<DirectoryOfServicesLocationsConnection>;
  serviceLocations?: Maybe<Array<Maybe<ServiceLocation>>>;
  areas?: Maybe<Array<Maybe<Area>>>;
  viewer?: Maybe<User>;
  userProfiles?: Maybe<Array<Maybe<EvaluatorProfile>>>;
  providerEmails?: Maybe<Array<Maybe<EmailModel>>>;
  isProviderRegistered?: Maybe<ProviderUserProfileType>;
  loginPageAccountType?: Maybe<Array<Maybe<Scalars['String']>>>;
  variationAgreements?: Maybe<Array<Maybe<VariationAgreementResult>>>;
  variationAgreementDownloadLink?: Maybe<Scalars['String']>;
  nhVariationAgreementReturns?: Maybe<NhVariationAgreementReturnConnection>;
  allNhVariationAgreementReturns?: Maybe<Array<Maybe<NhVariationAgreementReturn>>>;
};


export type QueryQuestionnaireArgs = {
  uuid?: Maybe<Scalars['String']>;
};


export type QueryQuestionnairesArgs = {
  submitted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  teStatus?: Maybe<Scalars['String']>;
  feStatus?: Maybe<Scalars['String']>;
  ccStatus?: Maybe<Scalars['String']>;
  overallStatus?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['String']>;
  mobilisationStatus?: Maybe<Scalars['String']>;
  contractSigned?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryQuestionnairesExportArgs = {
  outcome?: Maybe<Scalars['String']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QuerySectionArgs = {
  uuid?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
};


export type QueryThreadsArgs = {
  read?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  audience?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
};


export type QueryProcurementUserProfilesArgs = {
  complete?: Maybe<Scalars['Boolean']>;
};


export type QuerySectionsExportArgs = {
  uuid?: Maybe<Scalars['String']>;
};


export type QueryTeamArgs = {
  username?: Maybe<Scalars['String']>;
};


export type QueryClarificationQuestionArgs = {
  qid?: Maybe<Scalars['String']>;
};


export type QueryClarificationQuestionsArgs = {
  uuid?: Maybe<Scalars['String']>;
  isModeration?: Maybe<Scalars['Boolean']>;
};


export type QueryServiceLocationMobilisationsArgs = {
  legalEntityId?: Maybe<Scalars['ID']>;
};


export type QueryCapacityUpdatesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
};


export type QueryMobilisation2021Args = {
  uuid?: Maybe<Scalars['ID']>;
};


export type QueryStaffMemberDcArgs = {
  uuid?: Maybe<Scalars['String']>;
};


export type QueryDcVariationAgreementReturnsArgs = {
  variationUuid?: Maybe<Scalars['String']>;
  contractName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllDcVariationAgreementReturnsArgs = {
  variationUuid?: Maybe<Scalars['String']>;
};


export type QueryVacancyContactArgs = {
  uuid?: Maybe<Scalars['String']>;
};


export type QueryCareHomesArgs = {
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryDomcareQuestionnairesArgs = {
  uuid?: Maybe<Scalars['String']>;
  monthly?: Maybe<Scalars['Boolean']>;
  deadlineGte?: Maybe<Scalars['Date']>;
};


export type QueryFiltersTrackerArgs = {
  exclude?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  filterType?: Maybe<Scalars['String']>;
};


export type QueryDirectoryOfServicesLocationArgs = {
  id: Scalars['String'];
};


export type QueryDirectoryOfServicesLocationsArgs = {
  serviceTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  specialisms?: Maybe<Array<Maybe<Scalars['String']>>>;
  cqcRatings?: Maybe<Array<Maybe<Scalars['String']>>>;
  areas?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryServiceLocationsArgs = {
  missingIndividualContacts?: Maybe<Scalars['Boolean']>;
};


export type QueryAreasArgs = {
  inLondon?: Maybe<Scalars['Boolean']>;
};


export type QueryProviderEmailsArgs = {
  uuid: Scalars['String'];
};


export type QueryIsProviderRegisteredArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryLoginPageAccountTypeArgs = {
  uuid?: Maybe<Scalars['String']>;
};


export type QueryVariationAgreementsArgs = {
  agreementType?: Maybe<Scalars['String']>;
};


export type QueryVariationAgreementDownloadLinkArgs = {
  signatureRequestId?: Maybe<Scalars['String']>;
};


export type QueryNhVariationAgreementReturnsArgs = {
  variationUuid?: Maybe<Scalars['String']>;
  contractName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllNhVariationAgreementReturnsArgs = {
  variationUuid?: Maybe<Scalars['String']>;
};

export type Questionnaire = Node & {
  __typename?: 'Questionnaire';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  uuid: Scalars['String'];
  dateCompleted?: Maybe<Scalars['DateTime']>;
  user?: Maybe<ProcurementProvider>;
  sections?: Maybe<Array<Maybe<Section>>>;
  clarificationQuestionsDeadline?: Maybe<Scalars['DateTime']>;
  contractGenerated?: Maybe<Scalars['Boolean']>;
  contractIssued?: Maybe<Scalars['Boolean']>;
  contractIssuedNotEmbedded?: Maybe<Scalars['Boolean']>;
  contractSigned?: Maybe<Scalars['Boolean']>;
  signatureId?: Maybe<Scalars['String']>;
  signatureRequestId?: Maybe<Scalars['String']>;
  teClarificationQuestionsDeadline?: Maybe<Scalars['DateTime']>;
  outcomeLetterStatus?: Maybe<Scalars['String']>;
  letterIssued: Scalars['Boolean'];
  letterIssuedDate?: Maybe<Scalars['DateTime']>;
  answers?: Maybe<Array<Maybe<Answer>>>;
  lastSave?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  readOnly?: Maybe<Scalars['Boolean']>;
  ccStatus?: Maybe<Scalars['String']>;
  feStatus?: Maybe<Scalars['String']>;
  teStatus?: Maybe<Scalars['String']>;
  overallStatus?: Maybe<Scalars['String']>;
  clarificationQuestionsSubmittedDate?: Maybe<Scalars['DateTime']>;
  clarificationQuestionsPending?: Maybe<Scalars['Boolean']>;
  evaluatorStatus?: Maybe<Scalars['String']>;
  technicalEvaluatorProgress?: Maybe<Scalars['Int']>;
  technicalClarificationProgress?: Maybe<Array<Maybe<Scalars['Int']>>>;
  clinicalStatus?: Maybe<Scalars['String']>;
  commissioningStatus?: Maybe<Scalars['String']>;
  mobilisationStatus?: Maybe<Scalars['String']>;
  moderationOutcome?: Maybe<Scalars['String']>;
  moderationComment?: Maybe<Scalars['String']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};


export type ProcurementProvider = {
  __typename?: 'ProcurementProvider';
  id: Scalars['ID'];
  applicationName: Scalars['String'];
  evaluatorTeam?: Maybe<TeTeam>;
  legalEntity2021?: Maybe<LegalEntity2021>;
  provideruserprofile?: Maybe<ProviderUserProfileType>;
  qualificationquestionnaire?: Maybe<Questionnaire>;
  threadSet: ThreadConnection;
  hiddenThreads: ThreadConnection;
  messageSet: Array<Message>;
  messages: Array<Message>;
};


export type ProcurementProviderThreadSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  audience?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
};


export type ProcurementProviderHiddenThreadsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  audience?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
};

export type TeTeam = {
  __typename?: 'TETeam';
  id: Scalars['ID'];
  name: Scalars['String'];
  evaluators: Array<UserProfile>;
  evaluatorSet?: Maybe<Array<Maybe<Evaluator>>>;
  applications: Array<ProcurementProvider>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  isRemoved: Scalars['Boolean'];
  id: Scalars['ID'];
  /** A personal image displayed in your profile. */
  mugshot: Scalars['String'];
  /** Designates who can view your profile. */
  privacy: UserProfilePrivacy;
  user: User;
  approvedGrammar: Scalars['Boolean'];
  isEvaluator: Scalars['Boolean'];
  lastActivity?: Maybe<Scalars['DateTime']>;
  previousActivity?: Maybe<Scalars['DateTime']>;
  role: Scalars['String'];
  organisation: Scalars['String'];
  phone?: Maybe<PhoneModel>;
  secondaryEmail?: Maybe<EmailModel>;
  hasCustomGroups: Scalars['Boolean'];
  profileType: UserProfileProfileType;
  nhsType?: Maybe<UserProfileNhsType>;
  ccgs: Array<Ccg>;
  localAuthorities: Array<LocalAuthority>;
  commissionerprofile?: Maybe<CommissionerProfile>;
  provideruserprofile?: Maybe<ProviderUserProfileType>;
  teams: Array<TeTeam>;
  evaluatorSet: Array<Evaluator>;
  profileTypeDisplay?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum UserProfilePrivacy {
  /** Open */
  Open = 'OPEN',
  /** Registered */
  Registered = 'REGISTERED',
  /** Closed */
  Closed = 'CLOSED'
}

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  password: Scalars['String'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  lph?: Maybe<Scalars['Boolean']>;
  filterstrackerSet: FiltersTrackerConnection;
  userProfile?: Maybe<UserProfile>;
  notificationSet: Array<Notification>;
  exportFiles: ExportFileConnection;
  exportCsvEvents: ExportEventConnection;
  fullName?: Maybe<Scalars['String']>;
};


export type UserFilterstrackerSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  filterType?: Maybe<Scalars['String']>;
};


export type UserExportFilesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserExportCsvEventsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type FiltersTrackerConnection = {
  __typename?: 'FiltersTrackerConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FiltersTrackerEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

/** A Relay edge containing a `FiltersTracker` and its cursor. */
export type FiltersTrackerEdge = {
  __typename?: 'FiltersTrackerEdge';
  /** The item at the end of the edge */
  node?: Maybe<FiltersTracker>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type FiltersTracker = Node & {
  __typename?: 'FiltersTracker';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  user?: Maybe<User>;
  type: Scalars['String'];
  filter?: Maybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  id: Scalars['ID'];
  title: Scalars['String'];
  summary: Scalars['String'];
  content: Scalars['String'];
  createdBy: User;
  publishStart: Scalars['DateTime'];
  publishEnd?: Maybe<Scalars['DateTime']>;
  disabled: Scalars['Boolean'];
  prioritize: Scalars['Boolean'];
  prioritizeTill: Scalars['DateTime'];
  readStatus?: Maybe<Scalars['Boolean']>;
};

export type ExportFileConnection = {
  __typename?: 'ExportFileConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExportFileEdge>>;
};

/** A Relay edge containing a `ExportFile` and its cursor. */
export type ExportFileEdge = {
  __typename?: 'ExportFileEdge';
  /** The item at the end of the edge */
  node?: Maybe<ExportFile>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ExportFile = Node & {
  __typename?: 'ExportFile';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  status: ExportFileStatus;
  user?: Maybe<User>;
  /** The URL of file to download. */
  url?: Maybe<Scalars['String']>;
  /** The name of file to download. */
  fileName?: Maybe<Scalars['String']>;
  /** List of events associated with the export. */
  events?: Maybe<Array<ExportEvent>>;
};

/** An enumeration. */
export enum ExportFileStatus {
  /** Pending */
  Pending = 'PENDING',
  /** Success */
  Success = 'SUCCESS',
  /** Failed */
  Failed = 'FAILED',
  /** Deleted */
  Deleted = 'DELETED'
}

/** History log of export file. */
export type ExportEvent = Node & {
  __typename?: 'ExportEvent';
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Date when event happened at in ISO 8601 format. */
  date: Scalars['DateTime'];
  /** Export event type. */
  type: ExportEventsEnum;
  /** User who performed the action. */
  user?: Maybe<User>;
  /** Content of the event. */
  message?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ExportEventsEnum {
  ExportPending = 'EXPORT_PENDING',
  ExportSuccess = 'EXPORT_SUCCESS',
  ExportFailed = 'EXPORT_FAILED',
  ExportDeleted = 'EXPORT_DELETED',
  ExportedFileSent = 'EXPORTED_FILE_SENT',
  ExportFailedInfoSent = 'EXPORT_FAILED_INFO_SENT'
}

export type ExportEventConnection = {
  __typename?: 'ExportEventConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExportEventEdge>>;
};

/** A Relay edge containing a `ExportEvent` and its cursor. */
export type ExportEventEdge = {
  __typename?: 'ExportEventEdge';
  /** The item at the end of the edge */
  node?: Maybe<ExportEvent>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PhoneModel = {
  __typename?: 'PhoneModel';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['ID'];
  type: PhoneModelType;
  phoneNumber: Scalars['String'];
  ext?: Maybe<Scalars['Int']>;
  duplicate: Scalars['Boolean'];
  carepulseGenericsHeadofficecontactPhonenumber: Array<NhHeadOfficeContact>;
  carepulseGenericsCqccontactPhonenumber: Array<CqcContact>;
  userprofile?: Maybe<UserProfile>;
  providerdomcareSet: Array<ProviderDomCare>;
  domcareStaffmemberdcPhonenumber: Array<StaffMemberDc>;
  servicelocationSet: Array<ServiceLocation>;
  providerSet: Array<Provider>;
  carepulseStaffmemberchPhonenumber: Array<StaffMemberCh>;
  carehomeSet: Array<CareHome>;
  carepulseVacancycontactPhonenumber: Array<VacancyContact>;
  directoryofservicesproviderSet: Array<DirectoryOfServicesProvider>;
  directoryofserviceslocationSet: DirectoryOfServicesLocationsConnection;
  eprocurementServicelocationmobilisationPhonenumber: Array<ServiceLocationMobilisations>;
};


export type PhoneModelDirectoryofserviceslocationSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PhoneModelType {
  /** Direct */
  A_1 = 'A_1',
  /** Office */
  A_2 = 'A_2',
  /** Mobile */
  A_3 = 'A_3',
  /** Other */
  A_4 = 'A_4'
}

export type NhHeadOfficeContact = {
  __typename?: 'NHHeadOfficeContact';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  emails: Array<EmailModel>;
  phoneNumbers: Array<PhoneModel>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  approvedGrammar: Scalars['Boolean'];
  comment: Scalars['String'];
  id: Scalars['ID'];
  objectId?: Maybe<Scalars['Int']>;
  wantsContact: Scalars['Boolean'];
};

export type EmailModel = {
  __typename?: 'EmailModel';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['ID'];
  type: EmailModelType;
  address: Scalars['String'];
  duplicate: Scalars['Boolean'];
  carepulseGenericsHeadofficecontactEmailaddress: Array<NhHeadOfficeContact>;
  carepulseGenericsCqccontactEmailaddress: Array<CqcContact>;
  userprofile?: Maybe<UserProfile>;
  domcareStaffmemberdcEmailaddress: Array<StaffMemberDc>;
  carepulseStaffmemberchEmailaddress: Array<StaffMemberCh>;
  carepulseVacancycontactEmailaddress: Array<VacancyContact>;
  eprocurementServicelocationmobilisationEmailaddress: Array<ServiceLocationMobilisations>;
};

/** An enumeration. */
export enum EmailModelType {
  /** Work */
  A_1 = 'A_1',
  /** Home */
  A_2 = 'A_2',
  /** To */
  A_3 = 'A_3',
  /** CC */
  A_4 = 'A_4',
  /** Other */
  A_5 = 'A_5'
}

export type CqcContact = {
  __typename?: 'CqcContact';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  emails: Array<EmailModel>;
  phoneNumbers: Array<PhoneModel>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  approvedGrammar: Scalars['Boolean'];
  comment: Scalars['String'];
  id: Scalars['ID'];
  cqcregulatedactivitycontactSet: Array<CqcRegulatedActivityContact>;
};


export type CqcRegulatedActivityContact = {
  __typename?: 'CqcRegulatedActivityContact';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['ID'];
  cqcRegistration: CqcRegistration;
  contacts: Array<CqcContact>;
};

export type CqcRegistration = {
  __typename?: 'CqcRegistration';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['ID'];
  cqcName: Scalars['String'];
  cqcRegistrationStatus: Scalars['String'];
  lastReportPublicationDate?: Maybe<Scalars['Date']>;
  recordedId: Scalars['String'];
  cqcNew?: Maybe<CqcRatingNew>;
  oldAddress?: Maybe<Scalars['String']>;
  oldCounty?: Maybe<Scalars['String']>;
  oldPostcode?: Maybe<Scalars['String']>;
  oldStatus?: Maybe<Scalars['String']>;
  oldName?: Maybe<Scalars['String']>;
  oldLocalAuthority?: Maybe<Scalars['String']>;
  odsCode: Scalars['String'];
  cqcregulatedactivitycontactSet: Array<CqcRegulatedActivityContact>;
  providerdomcareSet: Array<ProviderDomCare>;
  servicelocationSet: Array<ServiceLocation>;
  providerSet: Array<Provider>;
  carehomeSet: Array<CareHome>;
  directoryofservicesproviderSet: Array<DirectoryOfServicesProvider>;
  directoryofserviceslocationSet: DirectoryOfServicesLocationsConnection;
};


export type CqcRegistrationDirectoryofserviceslocationSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type CqcRatingNew = {
  __typename?: 'CqcRatingNew';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['ID'];
  overall: Scalars['String'];
  safe: Scalars['String'];
  effective: Scalars['String'];
  caring: Scalars['String'];
  responsive: Scalars['String'];
  wellLed: Scalars['String'];
  cqcregistrationSet: Array<CqcRegistration>;
};

export type ProviderDomCare = {
  __typename?: 'ProviderDomCare';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  contractName?: Maybe<Scalars['String']>;
  cqcIdComment?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  county?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  pcgInPlace?: Maybe<Scalars['Boolean']>;
  cqcRegistration?: Maybe<CqcRegistration>;
  phaseJoined?: Maybe<Scalars['Int']>;
  aqpStatus: Scalars['Boolean'];
  phoneNumber?: Maybe<PhoneModel>;
  commentType?: Maybe<Scalars['String']>;
  sme?: Maybe<Scalars['Boolean']>;
  returnedVariationDeedDate?: Maybe<Scalars['Date']>;
  returnedVariationDeedDate1819?: Maybe<Scalars['Date']>;
  aqpStartDate?: Maybe<Scalars['Date']>;
  aqpEndDate?: Maybe<Scalars['Date']>;
  contractSuspended: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  headOffice?: Maybe<HeadOfficeDomCare>;
  legalEntity2021aqp?: Maybe<LegalEntity2021>;
  aqp2025StartDate?: Maybe<Scalars['DateTime']>;
  servicelocationSet: Array<ServiceLocation>;
  dcvariationagreementreturnSet: DcVariationAgreementReturnConnection;
};


export type ProviderDomCareDcvariationagreementreturnSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type HeadOfficeDomCare = {
  __typename?: 'HeadOfficeDomCare';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['ID'];
  address: Scalars['String'];
  postcode: Scalars['String'];
  providerdomcareSet: Array<ProviderDomCare>;
  headContacts?: Maybe<Array<Maybe<DcHeadOfficeContact>>>;
};

export type DcHeadOfficeContact = {
  __typename?: 'DCHeadOfficeContact';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  emails: Array<EmailModel>;
  phoneNumbers: Array<PhoneModel>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  approvedGrammar: Scalars['Boolean'];
  comment: Scalars['String'];
  id: Scalars['ID'];
  objectId?: Maybe<Scalars['Int']>;
  wantsContact: Scalars['Boolean'];
};

export type LegalEntity2021 = {
  __typename?: 'LegalEntity2021';
  uuid: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  representativeName: Scalars['String'];
  representativeAddress: Scalars['String'];
  representativeEmail: Scalars['String'];
  representativeTelephone: Scalars['String'];
  representativeRole: Scalars['String'];
  nominatedIndividual: Scalars['String'];
  informationGovernanceLead: Scalars['String'];
  caldicottGuardian: Scalars['String'];
  informationRiskOwner: Scalars['String'];
  accountableEmergencyOfficer: Scalars['String'];
  safeguardingLead: Scalars['String'];
  abuseLead: Scalars['String'];
  mentalAndDolLead: Scalars['String'];
  freedomToSpeakUpGuardian: Scalars['String'];
  dataProtectionOfficer: Scalars['String'];
  subcontractingDetails: Scalars['String'];
  signatoryName: Scalars['String'];
  dateCompleted?: Maybe<Scalars['DateTime']>;
  declarationName: Scalars['String'];
  declarationJobTitle: Scalars['String'];
  financialGuarantor: Scalars['String'];
  registeredOfficeAddress: Scalars['String'];
  companyCharityNumber: Scalars['String'];
  preventLead: Scalars['String'];
  mobilisationFormDateSubmitted?: Maybe<Scalars['Date']>;
  employersLiability: Scalars['String'];
  publicLiability: Scalars['String'];
  professionalIdemnity: Scalars['String'];
  serviceAgreement: Scalars['String'];
  signatoryJobTitle: Scalars['String'];
  providerdomcareSet: Array<ProviderDomCare>;
  domcareprocurementprofileSet: Array<ProcurementProvider>;
};

export type ServiceLocation = {
  __typename?: 'ServiceLocation';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  address: Scalars['String'];
  county?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  aqpStatus: Scalars['Boolean'];
  contractSuspended: Scalars['Boolean'];
  commissionerInfo: Scalars['String'];
  embargo: Scalars['Boolean'];
  website?: Maybe<Scalars['String']>;
  changedDetails: Scalars['String'];
  comments: Scalars['String'];
  reporting: Scalars['Boolean'];
  cqcRegistration?: Maybe<CqcRegistration>;
  phoneNumber?: Maybe<PhoneModel>;
  aqpStartDate?: Maybe<Scalars['Date']>;
  aqpEndDate?: Maybe<Scalars['Date']>;
  reportingStartDate?: Maybe<Scalars['Date']>;
  reportingEndDate?: Maybe<Scalars['Date']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  closed: Scalars['Boolean'];
  serviceUserNumber: Scalars['Int'];
  serviceTypes: Array<ServiceType>;
  serviceUserBands: Array<ServiceUserBand>;
  spokenLanguages: Array<LanguageSelect>;
  covidRestriction: Scalars['Boolean'];
  liveIn: ServiceLocationLiveIn;
  provider?: Maybe<ProviderDomCare>;
  areasServed: Array<Ccg>;
  staff: Array<StaffMemberDc>;
  cqcIdComment?: Maybe<Scalars['String']>;
  careTiers: Array<CareTier>;
  specialisms: Array<Specialisms>;
  otherSpecialisms: Scalars['String'];
  languages: Scalars['String'];
  lastSubmittedQualityQuestionnaire?: Maybe<Scalars['Date']>;
  aqpStatus1819: Scalars['Boolean'];
  aqpStatus1920: Scalars['Boolean'];
  aqp2025StartDate?: Maybe<Scalars['DateTime']>;
  reporting2025StartDate?: Maybe<Scalars['DateTime']>;
  capacityUpdated?: Maybe<Scalars['DateTime']>;
  capacityEmailSent?: Maybe<Scalars['DateTime']>;
  capacityupdateSet: CapacityUpdateConnection;
  domcarequestionnaireSet: Array<DomCareQuestionnaire>;
  serviceLocationMobilisations: Array<ServiceLocationMobilisations>;
  servicelocationmobilisation?: Maybe<ServiceLocationMobilisations>;
};


export type ServiceLocationCapacityupdateSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
};

export type ServiceType = {
  __typename?: 'ServiceType';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  servicelocationSet: Array<ServiceLocation>;
  carehomeSet: Array<CareHome>;
  directoryofserviceslocationSet: DirectoryOfServicesLocationsConnection;
};


export type ServiceTypeDirectoryofserviceslocationSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type CareHome = {
  __typename?: 'CareHome';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  address: Scalars['String'];
  county?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  aqpStatus: Scalars['Boolean'];
  contractSuspended: Scalars['Boolean'];
  commissionerInfo: Scalars['String'];
  embargo: Scalars['Boolean'];
  website?: Maybe<Scalars['String']>;
  changedDetails: Scalars['String'];
  comments: Scalars['String'];
  reporting: Scalars['Boolean'];
  cqcRegistration?: Maybe<CqcRegistration>;
  phoneNumber?: Maybe<PhoneModel>;
  aqpStartDate?: Maybe<Scalars['Date']>;
  aqpEndDate?: Maybe<Scalars['Date']>;
  reportingStartDate?: Maybe<Scalars['Date']>;
  reportingEndDate?: Maybe<Scalars['Date']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  closed: Scalars['Boolean'];
  serviceUserNumber: Scalars['Int'];
  serviceTypes: Array<ServiceType>;
  serviceUserBands: Array<ServiceUserBand>;
  spokenLanguages: Array<LanguageSelect>;
  covidRestriction: Scalars['Boolean'];
  id: Scalars['ID'];
  provider: Provider;
  localAuthority?: Maybe<LocalAuthority>;
  ccg?: Maybe<Ccg>;
  staff: Array<StaffMemberCh>;
  noOccupiedBeds: Scalars['Int'];
  aqpStatus1819: Scalars['Boolean'];
  aqpStatus1718: Scalars['Boolean'];
  ageCategory: CareHomeAgeCategory;
  noBeds?: Maybe<Scalars['Int']>;
  specialisms: Scalars['String'];
  noCapacityForAqpPlacements: Scalars['Boolean'];
  vacantBeds?: Maybe<Scalars['Int']>;
  lastSubmittedQualityQuestionnaire?: Maybe<Scalars['Date']>;
  selfImposedSuspension: Scalars['Boolean'];
  selfImposedSuspensionDate?: Maybe<Scalars['Date']>;
  vacancyReporting: Scalars['Boolean'];
  vacancyEmailSent?: Maybe<Scalars['DateTime']>;
  vacancyUpdated?: Maybe<Scalars['DateTime']>;
  vacancyLastRequested?: Maybe<Scalars['DateTime']>;
  carehomestaffpositionsSet: Array<CareHomeStaffPositions>;
  vacancyContacts: Array<VacancyContact>;
  vacancyinfoSet: Array<VacancyInfo>;
  bedTypePreferences: Array<BedType>;
  provideruserprofileSet: Array<ProviderUserProfileType>;
  lastVacancyInfo?: Maybe<VacancyInfo>;
};

export type ServiceUserBand = {
  __typename?: 'ServiceUserBand';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  servicelocationSet: Array<ServiceLocation>;
  carehomeSet: Array<CareHome>;
  directoryofserviceslocationSet: DirectoryOfServicesLocationsConnection;
};


export type ServiceUserBandDirectoryofserviceslocationSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type DirectoryOfServicesLocationsConnection = {
  __typename?: 'DirectoryOfServicesLocationsConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DirectoryOfServicesLocationsEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DirectoryOfServicesLocations` and its cursor. */
export type DirectoryOfServicesLocationsEdge = {
  __typename?: 'DirectoryOfServicesLocationsEdge';
  /** The item at the end of the edge */
  node?: Maybe<DirectoryOfServicesLocations>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type DirectoryOfServicesLocations = Node & {
  __typename?: 'DirectoryOfServicesLocations';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  address: Scalars['String'];
  county?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  aqpStatus: Scalars['Boolean'];
  contractSuspended: Scalars['Boolean'];
  commissionerInfo: Scalars['String'];
  embargo: Scalars['Boolean'];
  website?: Maybe<Scalars['String']>;
  changedDetails: Scalars['String'];
  comments: Scalars['String'];
  reporting: Scalars['Boolean'];
  cqcRegistration?: Maybe<CqcRegistration>;
  phoneNumber?: Maybe<PhoneModel>;
  aqpStartDate?: Maybe<Scalars['Date']>;
  aqpEndDate?: Maybe<Scalars['Date']>;
  reportingStartDate?: Maybe<Scalars['Date']>;
  reportingEndDate?: Maybe<Scalars['Date']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  closed: Scalars['Boolean'];
  serviceUserNumber: Scalars['Int'];
  serviceTypes: Array<ServiceType>;
  serviceUserBands: Array<ServiceUserBand>;
  spokenLanguages: Array<LanguageSelect>;
  covidRestriction: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  provider?: Maybe<DirectoryOfServicesProvider>;
  specialisms: Array<Specialisms>;
  noBeds?: Maybe<Scalars['Int']>;
  localAuthority?: Maybe<LocalAuthority>;
};

export type LanguageSelect = {
  __typename?: 'LanguageSelect';
  id: Scalars['ID'];
  name: Scalars['String'];
  code: Scalars['String'];
  iso: Scalars['String'];
  servicelocationSet: Array<ServiceLocation>;
  carehomeSet: Array<CareHome>;
  directoryofserviceslocationSet: DirectoryOfServicesLocationsConnection;
  servicelocationmobilisationSet: Array<ServiceLocationMobilisations>;
};


export type LanguageSelectDirectoryofserviceslocationSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ServiceLocationMobilisations = {
  __typename?: 'ServiceLocationMobilisations';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  address: Scalars['String'];
  county?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  aqpStatus: Scalars['Boolean'];
  contractSuspended: Scalars['Boolean'];
  commissionerInfo: Scalars['String'];
  embargo: Scalars['Boolean'];
  website?: Maybe<Scalars['String']>;
  changedDetails: Scalars['String'];
  comments: Scalars['String'];
  reporting: Scalars['Boolean'];
  cqcRegistration?: Maybe<CqcRegistration>;
  phoneNumber?: Maybe<PhoneModel>;
  aqpStartDate?: Maybe<Scalars['Date']>;
  aqpEndDate?: Maybe<Scalars['Date']>;
  reportingStartDate?: Maybe<Scalars['Date']>;
  reportingEndDate?: Maybe<Scalars['Date']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  closed: Scalars['Boolean'];
  serviceUserNumber: Scalars['Int'];
  covidRestriction: Scalars['Boolean'];
  liveIn: ServiceLocationLiveIn;
  provider?: Maybe<ProviderDomCare>;
  cqcIdComment?: Maybe<Scalars['String']>;
  otherSpecialisms: Scalars['String'];
  languages: Scalars['String'];
  lastSubmittedQualityQuestionnaire?: Maybe<Scalars['Date']>;
  aqpStatus1819: Scalars['Boolean'];
  aqpStatus1920: Scalars['Boolean'];
  aqp2025StartDate?: Maybe<Scalars['DateTime']>;
  reporting2025StartDate?: Maybe<Scalars['DateTime']>;
  capacityUpdated?: Maybe<Scalars['DateTime']>;
  capacityEmailSent?: Maybe<Scalars['DateTime']>;
  servicelocationPtr: ServiceLocation;
  serviceLocation?: Maybe<ServiceLocation>;
  careTiersMobilisation: Array<CareTier>;
  liveInMobilisation: ServiceLocationMobilisationLiveInMobilisation;
  areasServedMobilisation: Array<Ccg>;
  specialismsMobilisation: Array<Specialisms>;
  languagesMobilisation: Array<LanguageSelect>;
  managerFirstNameMobilisation: Scalars['String'];
  managerLastNameMobilisation: Scalars['String'];
  jobTitle: Scalars['String'];
  emailsMobilisation: Array<EmailModel>;
  phoneNumbersMobilisation: Array<PhoneModel>;
  aqpStatusMobilisation: Scalars['Boolean'];
};

/** An enumeration. */
export enum ServiceLocationLiveIn {
  /** Unknown */
  A_1 = 'A_1',
  /** No live-in care */
  A_2 = 'A_2',
  /** Live-in care */
  A_3 = 'A_3',
  /** Live-in care only */
  A_4 = 'A_4'
}

export type CareTier = {
  __typename?: 'CareTier';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['ID'];
  tier: Scalars['Int'];
  name: Scalars['String'];
  servicelocationSet: Array<ServiceLocation>;
  servicelocationmobilisationSet: Array<ServiceLocationMobilisations>;
};

/** An enumeration. */
export enum ServiceLocationMobilisationLiveInMobilisation {
  /** Unknown */
  A_1 = 'A_1',
  /** No live-in care */
  A_2 = 'A_2',
  /** Live-in care */
  A_3 = 'A_3',
  /** Live-in care only */
  A_4 = 'A_4'
}

export type Ccg = {
  __typename?: 'Ccg';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  alternateName: Scalars['String'];
  mergerName: Scalars['String'];
  ccgOnHomeAqp: Scalars['Boolean'];
  ccgOnDcAqp: Scalars['Boolean'];
  area?: Maybe<Area>;
  inLondon: Scalars['Boolean'];
  code: Scalars['String'];
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  localauthoritySet: Array<LocalAuthority>;
  userprofileSet: Array<UserProfile>;
  commissionerprofileSet: Array<CommissionerProfile>;
  servicelocationSet: Array<ServiceLocation>;
  carehomeSet: Array<CareHome>;
  servicelocationmobilisationSet: Array<ServiceLocationMobilisations>;
};

export type Area = {
  __typename?: 'Area';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  fullName: Scalars['String'];
  ccgSet: Array<Ccg>;
};

export type LocalAuthority = {
  __typename?: 'LocalAuthority';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  officialName: Scalars['String'];
  alternateName: Scalars['String'];
  inLondon: Scalars['Boolean'];
  ccg?: Maybe<Ccg>;
  userprofileSet: Array<UserProfile>;
  capacityUpdates: CapacityUpdateConnection;
  carehomeSet: Array<CareHome>;
  directoryofserviceslocationSet: DirectoryOfServicesLocationsConnection;
};


export type LocalAuthorityCapacityUpdatesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
};


export type LocalAuthorityDirectoryofserviceslocationSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type CapacityUpdateConnection = {
  __typename?: 'CapacityUpdateConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CapacityUpdateEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CapacityUpdate` and its cursor. */
export type CapacityUpdateEdge = {
  __typename?: 'CapacityUpdateEdge';
  /** The item at the end of the edge */
  node?: Maybe<CapacityUpdate>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CapacityUpdate = Node & {
  __typename?: 'CapacityUpdate';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  serviceLocation: ServiceLocation;
  areas: Array<LocalAuthority>;
  minimumCallDuration?: Maybe<Scalars['String']>;
  noticeRequired?: Maybe<Scalars['String']>;
  comments: Scalars['String'];
  acceptsCovid?: Maybe<Scalars['Boolean']>;
  acceptsCovidComment: Scalars['String'];
};

export type CommissionerProfile = {
  __typename?: 'CommissionerProfile';
  isRemoved: Scalars['Boolean'];
  id: Scalars['ID'];
  /** A personal image displayed in your profile. */
  mugshot: Scalars['String'];
  /** Designates who can view your profile. */
  privacy: UserProfilePrivacy;
  user: User;
  approvedGrammar: Scalars['Boolean'];
  isEvaluator: Scalars['Boolean'];
  lastActivity?: Maybe<Scalars['DateTime']>;
  previousActivity?: Maybe<Scalars['DateTime']>;
  role: Scalars['String'];
  organisation: Scalars['String'];
  phone?: Maybe<PhoneModel>;
  secondaryEmail?: Maybe<EmailModel>;
  hasCustomGroups: Scalars['Boolean'];
  profileType: UserProfileProfileType;
  nhsType?: Maybe<UserProfileNhsType>;
  userprofilePtr: UserProfile;
  ccg: Array<Ccg>;
};

/** An enumeration. */
export enum UserProfileProfileType {
  /** CCG */
  A_0 = 'A_0',
  /** LA */
  A_1 = 'A_1',
  /** Hospital */
  A_3 = 'A_3',
  /** NHSE / NHSI */
  A_4 = 'A_4',
  /** CSU */
  A_5 = 'A_5',
  /** Other */
  A_2 = 'A_2',
  /** LPH */
  A_6 = 'A_6',
  /** Provider */
  A_10 = 'A_10'
}

/** An enumeration. */
export enum UserProfileNhsType {
  /** NHS England */
  A_1 = 'A_1',
  /** NHS Improvement */
  A_2 = 'A_2'
}

export type Specialisms = {
  __typename?: 'Specialisms';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  servicelocationSet: Array<ServiceLocation>;
  directoryofserviceslocationSet: DirectoryOfServicesLocationsConnection;
  servicelocationmobilisationSet: Array<ServiceLocationMobilisations>;
};


export type SpecialismsDirectoryofserviceslocationSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type DirectoryOfServicesProvider = {
  __typename?: 'DirectoryOfServicesProvider';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  contractName?: Maybe<Scalars['String']>;
  cqcIdComment?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  county?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  pcgInPlace?: Maybe<Scalars['Boolean']>;
  cqcRegistration?: Maybe<CqcRegistration>;
  phaseJoined?: Maybe<Scalars['Int']>;
  aqpStatus: Scalars['Boolean'];
  phoneNumber?: Maybe<PhoneModel>;
  commentType?: Maybe<Scalars['String']>;
  sme?: Maybe<Scalars['Boolean']>;
  returnedVariationDeedDate?: Maybe<Scalars['Date']>;
  returnedVariationDeedDate1819?: Maybe<Scalars['Date']>;
  aqpStartDate?: Maybe<Scalars['Date']>;
  aqpEndDate?: Maybe<Scalars['Date']>;
  contractSuspended: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  id: Scalars['ID'];
  locations: DirectoryOfServicesLocationsConnection;
};


export type DirectoryOfServicesProviderLocationsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Provider = {
  __typename?: 'Provider';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  contractName?: Maybe<Scalars['String']>;
  cqcIdComment?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  county?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  pcgInPlace?: Maybe<Scalars['Boolean']>;
  cqcRegistration?: Maybe<CqcRegistration>;
  phaseJoined?: Maybe<Scalars['Int']>;
  aqpStatus: Scalars['Boolean'];
  phoneNumber?: Maybe<PhoneModel>;
  commentType?: Maybe<Scalars['String']>;
  sme?: Maybe<Scalars['Boolean']>;
  returnedVariationDeedDate?: Maybe<Scalars['Date']>;
  returnedVariationDeedDate1819?: Maybe<Scalars['Date']>;
  aqpStartDate?: Maybe<Scalars['Date']>;
  aqpEndDate?: Maybe<Scalars['Date']>;
  contractSuspended: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  id: Scalars['ID'];
  headOffice?: Maybe<HeadOfficeHome>;
  legalEntity1819aqp?: Maybe<LegalEntity1819Aqp>;
  defaultAqpHome: Scalars['String'];
  contractReturned1819?: Maybe<Scalars['Date']>;
  carehomeSet: Array<CareHome>;
  nhvariationagreementreturnSet: NhVariationAgreementReturnConnection;
};


export type ProviderNhvariationagreementreturnSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type HeadOfficeHome = {
  __typename?: 'HeadOfficeHome';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  address: Scalars['String'];
  postcode: Scalars['String'];
  id: Scalars['ID'];
  providerSet: Array<Provider>;
  headContacts?: Maybe<Array<Maybe<NhHeadOfficeContact>>>;
};

export type LegalEntity1819Aqp = {
  __typename?: 'LegalEntity1819AQP';
  uuid: Scalars['String'];
  name: Scalars['String'];
  representativeName: Scalars['String'];
  representativeAddress: Scalars['String'];
  representativeEmail: Scalars['String'];
  representativeTelephone: Scalars['String'];
  representativeRole: Scalars['String'];
  nominatedIndividual: Scalars['String'];
  informationGovernanceLead: Scalars['String'];
  caldicottGuardian: Scalars['String'];
  informationRiskOwner: Scalars['String'];
  accountableEmergencyOfficer: Scalars['String'];
  safeguardingLead: Scalars['String'];
  abuseLead: Scalars['String'];
  mentalAndDolLead: Scalars['String'];
  freedomToSpeakUpGuardian: Scalars['String'];
  dataProtectionOfficer: Scalars['String'];
  subcontractingDetails: Scalars['String'];
  signatoryName: Scalars['String'];
  dateCompleted?: Maybe<Scalars['DateTime']>;
  declarationName: Scalars['String'];
  declarationJobTitle: Scalars['String'];
  financialGuarantor: Scalars['String'];
  registeredOfficeAddress: Scalars['String'];
  companyCharityNumber: Scalars['String'];
  id: Scalars['ID'];
  providerSet: Array<Provider>;
};

export type NhVariationAgreementReturnConnection = {
  __typename?: 'NHVariationAgreementReturnConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NhVariationAgreementReturnEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `NHVariationAgreementReturn` and its cursor. */
export type NhVariationAgreementReturnEdge = {
  __typename?: 'NHVariationAgreementReturnEdge';
  /** The item at the end of the edge */
  node?: Maybe<NhVariationAgreementReturn>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type NhVariationAgreementReturn = Node & {
  __typename?: 'NHVariationAgreementReturn';
  /** The ID of the object. */
  id: Scalars['ID'];
  provider: Provider;
  variationAgreement: NhVariationAgreement;
  returnedAt?: Maybe<Scalars['Date']>;
  signatureRequestId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  viewedAt?: Maybe<Scalars['DateTime']>;
  emailBounced: Scalars['Boolean'];
  lastRemindedAt?: Maybe<Scalars['DateTime']>;
  lastReissuedAt?: Maybe<Scalars['DateTime']>;
};

export type NhVariationAgreement = {
  __typename?: 'NHVariationAgreement';
  uuid: Scalars['String'];
  period: Scalars['String'];
  label: Scalars['String'];
  target: Scalars['Int'];
  deadline?: Maybe<Scalars['Date']>;
  showFilter: Scalars['Boolean'];
  hellosign: Scalars['Boolean'];
  id: Scalars['ID'];
  nhvariationagreementreturnSet: NhVariationAgreementReturnConnection;
};


export type NhVariationAgreementNhvariationagreementreturnSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type StaffMemberCh = {
  __typename?: 'StaffMemberCH';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<EmailModel>>>;
  phoneNumbers: Array<PhoneModel>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  approvedGrammar: Scalars['Boolean'];
  comment: Scalars['String'];
  id: Scalars['ID'];
  mainStage: Scalars['Boolean'];
  reminderMail: Scalars['Boolean'];
  deadlinePassed: Scalars['Boolean'];
  warning: Scalars['Boolean'];
  draftDashboard: Scalars['Boolean'];
  finalDashboard: Scalars['Boolean'];
  carehomeSet: Array<CareHome>;
  carehomestaffpositionsSet: Array<CareHomeStaffPositions>;
  provideruserprofileSet: Array<ProviderUserProfileType>;
};

export type CareHomeStaffPositions = {
  __typename?: 'CareHomeStaffPositions';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['ID'];
  careHome: CareHome;
  staffmemberch: StaffMemberCh;
};

export type ProviderUserProfileType = {
  __typename?: 'ProviderUserProfileType';
  isRemoved: Scalars['Boolean'];
  userprofilePtr: UserProfile;
  id: Scalars['ID'];
  /** A personal image displayed in your profile. */
  mugshot: Scalars['String'];
  /** Designates who can view your profile. */
  privacy: UserProfilePrivacy;
  user: User;
  approvedGrammar: Scalars['Boolean'];
  isEvaluator: Scalars['Boolean'];
  lastActivity?: Maybe<Scalars['DateTime']>;
  previousActivity?: Maybe<Scalars['DateTime']>;
  role: Scalars['String'];
  organisation: Scalars['String'];
  phone?: Maybe<PhoneModel>;
  secondaryEmail?: Maybe<EmailModel>;
  hasCustomGroups: Scalars['Boolean'];
  profileType: UserProfileProfileType;
  nhsType?: Maybe<UserProfileNhsType>;
  careHomes: Array<CareHome>;
  displayRejectedNotification: Scalars['Boolean'];
  staffDc?: Maybe<StaffMemberDc>;
  staff?: Maybe<StaffMemberCh>;
  vacancyContact?: Maybe<VacancyContact>;
  domcareProcurement?: Maybe<ProcurementProvider>;
  allCareHomes?: Maybe<Array<Maybe<CareHome>>>;
  contacts?: Maybe<Array<Maybe<Scalars['String']>>>;
  homepage?: Maybe<Scalars['String']>;
  activationKey?: Maybe<Scalars['String']>;
};

export type StaffMemberDc = {
  __typename?: 'StaffMemberDC';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  emails: Array<EmailModel>;
  phoneNumbers: Array<PhoneModel>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  approvedGrammar: Scalars['Boolean'];
  comment: Scalars['String'];
  servicelocationSet: Array<ServiceLocation>;
  provideruserprofileSet: Array<ProviderUserProfileType>;
  serviceLocations?: Maybe<Array<Maybe<ServiceLocation>>>;
};

export type VacancyContact = {
  __typename?: 'VacancyContact';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  emails: Array<EmailModel>;
  phoneNumbers: Array<PhoneModel>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  approvedGrammar: Scalars['Boolean'];
  comment: Scalars['String'];
  id: Scalars['ID'];
  vacancyReporting: Scalars['Boolean'];
  vacancyEmailSent?: Maybe<Scalars['DateTime']>;
  careHomes: Array<CareHome>;
  provideruserprofileSet: Array<ProviderUserProfileType>;
};

/** An enumeration. */
export enum CareHomeAgeCategory {
  /** Over 65 */
  A_1 = 'A_1',
  /** Under 65 */
  A_2 = 'A_2',
  /** Over and under 65 */
  A_3 = 'A_3',
  /** Unknown */
  A_0 = 'A_0'
}

export type VacancyInfo = {
  __typename?: 'VacancyInfo';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  id: Scalars['ID'];
  careHome: CareHome;
  vacantBeds: Scalars['Int'];
  comments?: Maybe<Scalars['String']>;
  bedTypes: Array<BedType>;
  vacancyupdateSet: Array<VacancyUpdate>;
};

export type BedType = {
  __typename?: 'BedType';
  id: Scalars['ID'];
  name: Scalars['String'];
  careHomes: Array<CareHome>;
  vacancyinfoSet: Array<VacancyInfo>;
  vacancyupdateSet: Array<VacancyUpdate>;
};

export type VacancyUpdate = {
  __typename?: 'VacancyUpdate';
  id: Scalars['ID'];
  vacancyInfo: VacancyInfo;
  bedType: BedType;
  vacantBeds: Scalars['Int'];
};

export type DomCareQuestionnaire = {
  __typename?: 'DomCareQuestionnaire';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['ID'];
  lastPostAccessed?: Maybe<Scalars['DateTime']>;
  lastGetAccessed?: Maybe<Scalars['DateTime']>;
  submissionAttempts: Scalars['Int'];
  amountOfPosts: Scalars['Int'];
  amountOfValidSavings: Scalars['Int'];
  amountOfSubmissionsIfCompleted: Scalars['Int'];
  amountOfGets: Scalars['Int'];
  month: Scalars['Int'];
  year: Scalars['Int'];
  deadline: Scalars['DateTime'];
  dateCompleted?: Maybe<Scalars['DateTime']>;
  earlyAlert: Scalars['Boolean'];
  mainStage: Scalars['Boolean'];
  reminderMail: Scalars['Boolean'];
  cqWarning: Scalars['Boolean'];
  contractQueries: Scalars['Boolean'];
  cmMeeting: Scalars['Boolean'];
  cqWithdrawn: Scalars['Boolean'];
  warning: Scalars['Boolean'];
  draftDashboard: Scalars['Boolean'];
  finalDashboard: Scalars['Boolean'];
  version: Scalars['Int'];
  noServiceUsers: Scalars['Boolean'];
  dashboardReviewDeadline?: Maybe<Scalars['DateTime']>;
  draftDashboardReleaseDate?: Maybe<Scalars['DateTime']>;
  finalDashboardReleaseDate?: Maybe<Scalars['DateTime']>;
  initialNextEmailDate?: Maybe<Scalars['DateTime']>;
  reportingPeriod: Scalars['String'];
  nextDeadline?: Maybe<Scalars['DateTime']>;
  doNotContact: Scalars['Boolean'];
  chasingEmailSent?: Maybe<Scalars['DateTime']>;
  covidReminderSent?: Maybe<Scalars['DateTime']>;
  reportingWithdrawn: Scalars['Boolean'];
  filledOutFor: ServiceLocation;
  filledOutByComment?: Maybe<Scalars['String']>;
  serviceLocationManagerComment?: Maybe<Scalars['String']>;
  questions: Array<DomCareQuestion>;
  domcareanswerSet: Array<DomCareAnswer>;
};

export type DomCareQuestion = {
  __typename?: 'DomCareQuestion';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['ID'];
  questionText: Scalars['String'];
  questionDescription?: Maybe<Scalars['String']>;
  questionExample?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
  order: Scalars['Int'];
  monthly: Scalars['Boolean'];
  sectionBreak: Scalars['Boolean'];
  questionnaire: Array<DomCareQuestionnaire>;
  isCategory: DomCareQuestionIscategory;
  domcareanswerSet: Array<DomCareAnswer>;
};

/** An enumeration. */
export enum DomCareQuestionIscategory {
  /** Provider information */
  A_1 = 'A_1',
  /** Numbers of service users */
  A_2 = 'A_2',
  /** End of life care and service user death */
  A_3 = 'A_3',
  /** Services integration  */
  A_4 = 'A_4',
  /** Service user safety */
  A_5 = 'A_5',
  /** Punctuality and continuity of care */
  A_6 = 'A_6',
  /** Staffing */
  A_7 = 'A_7',
  /** Feedback and continuous improvement */
  A_8 = 'A_8'
}

export type DomCareAnswer = {
  __typename?: 'DomCareAnswer';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['ID'];
  answerText: Scalars['String'];
  answerComment: Scalars['String'];
  staffComment?: Maybe<Scalars['String']>;
  currentStatus: DomCareAnswerCurrentStatus;
  edited: Scalars['Boolean'];
  datetimeEdited?: Maybe<Scalars['DateTime']>;
  question: DomCareQuestion;
  questionnaire: DomCareQuestionnaire;
};

/** An enumeration. */
export enum DomCareAnswerCurrentStatus {
  /** Not checked */
  A_1 = 'A_1',
  /** Checked - OK */
  A_2 = 'A_2',
  /** Checked - Not OK */
  A_3 = 'A_3',
  /** Add to dashboard */
  A_4 = 'A_4',
  /** PID */
  A_5 = 'A_5'
}

export type DcVariationAgreementReturnConnection = {
  __typename?: 'DCVariationAgreementReturnConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DcVariationAgreementReturnEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DCVariationAgreementReturn` and its cursor. */
export type DcVariationAgreementReturnEdge = {
  __typename?: 'DCVariationAgreementReturnEdge';
  /** The item at the end of the edge */
  node?: Maybe<DcVariationAgreementReturn>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type DcVariationAgreementReturn = Node & {
  __typename?: 'DCVariationAgreementReturn';
  /** The ID of the object. */
  id: Scalars['ID'];
  provider: ProviderDomCare;
  variationAgreement: DcVariationAgreement;
  returnedAt?: Maybe<Scalars['Date']>;
  signatureRequestId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  viewedAt?: Maybe<Scalars['DateTime']>;
  emailBounced: Scalars['Boolean'];
  lastRemindedAt?: Maybe<Scalars['DateTime']>;
  lastReissuedAt?: Maybe<Scalars['DateTime']>;
};

export type DcVariationAgreement = {
  __typename?: 'DCVariationAgreement';
  uuid: Scalars['String'];
  id: Scalars['ID'];
  period: Scalars['String'];
  label: Scalars['String'];
  target: Scalars['Int'];
  deadline?: Maybe<Scalars['Date']>;
  showFilter: Scalars['Boolean'];
  hellosign: Scalars['Boolean'];
  dcvariationagreementreturnSet: DcVariationAgreementReturnConnection;
};


export type DcVariationAgreementDcvariationagreementreturnSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type Evaluator = {
  __typename?: 'Evaluator';
  id: Scalars['ID'];
  team: TeTeam;
  evaluator?: Maybe<EvaluatorProfile>;
  evaluatorType?: Maybe<EvaluatorEvaluatorType>;
  evaluatorTypeDisplay?: Maybe<Scalars['String']>;
};

export type EvaluatorProfile = {
  __typename?: 'EvaluatorProfile';
  id?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
};

/** An enumeration. */
export enum EvaluatorEvaluatorType {
  /** commissioning */
  Com = 'COM',
  /** clinical */
  Clin = 'CLIN'
}

export type ThreadConnection = {
  __typename?: 'ThreadConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ThreadEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Thread` and its cursor. */
export type ThreadEdge = {
  __typename?: 'ThreadEdge';
  /** The item at the end of the edge */
  node?: Maybe<Thread>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Thread = Node & {
  __typename?: 'Thread';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  sendTo?: Maybe<ProcurementProvider>;
  hiddenFrom: Array<ProcurementProvider>;
  messages: Array<Message>;
};

export type Message = {
  __typename?: 'Message';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  id: Scalars['ID'];
  audience: MessageAudience;
  thread: ThreadConnection;
  sender?: Maybe<ProcurementProvider>;
  body: Scalars['String'];
  subject: Scalars['String'];
  readBy: Array<ProcurementProvider>;
  attachment?: Maybe<MessageAttachment>;
  read?: Maybe<Scalars['Boolean']>;
};


export type MessageThreadArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  audience?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum MessageAudience {
  /** Private */
  Private = 'PRIVATE',
  /** Public */
  Public = 'PUBLIC'
}

export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
  message?: Maybe<Message>;
};

export type Section = {
  __typename?: 'Section';
  id: Scalars['ID'];
  title: Scalars['String'];
  order: Scalars['Int'];
  questionnaires: QuestionnaireConnection;
  categories: Array<Category>;
  status?: Maybe<Scalars['String']>;
  cqStatus?: Maybe<Scalars['String']>;
  cqDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  complianceStatus?: Maybe<Scalars['String']>;
};


export type SectionQuestionnairesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QuestionnaireConnection = {
  __typename?: 'QuestionnaireConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<QuestionnaireEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Questionnaire` and its cursor. */
export type QuestionnaireEdge = {
  __typename?: 'QuestionnaireEdge';
  /** The item at the end of the edge */
  node?: Maybe<Questionnaire>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  order: Scalars['Int'];
  title: Scalars['String'];
  details: Scalars['String'];
  section: Section;
  questions: Array<Question>;
};

export type Question = {
  __typename?: 'Question';
  id: Scalars['ID'];
  choices?: Maybe<Array<Maybe<Scalars['String']>>>;
  type: QualificationQuestionType;
  category: Category;
  order: Scalars['Int'];
  title: Scalars['String'];
  details: Scalars['String'];
  mandatory: Scalars['Boolean'];
  notApplicable: Scalars['Boolean'];
  commentBox: Scalars['Boolean'];
  charLimit?: Maybe<Scalars['Int']>;
  answers: Array<Answer>;
};

/** An enumeration. */
export enum QualificationQuestionType {
  /** Text */
  Text = 'TEXT',
  /** Text area */
  Textarea = 'TEXTAREA',
  /** Number */
  Number = 'NUMBER',
  /** Radio */
  Radio = 'RADIO',
  /** Checkbox */
  Checkbox = 'CHECKBOX',
  /** Select */
  Select = 'SELECT',
  /** Select multiple */
  SelectMultiple = 'SELECT_MULTIPLE',
  /** Date */
  Date = 'DATE',
  /** File */
  File = 'FILE',
  /** Phone */
  Phone = 'PHONE',
  /** Email */
  Email = 'EMAIL',
  /** CQC ID */
  CqcId = 'CQC_ID',
  /** Currency */
  Currency = 'CURRENCY'
}

export type Answer = {
  __typename?: 'Answer';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  id: Scalars['ID'];
  commissioningOutcome?: Maybe<Scalars['String']>;
  clinicalOutcome?: Maybe<Scalars['String']>;
  moderationOutcome?: Maybe<Scalars['String']>;
  commissioningRationale?: Maybe<Scalars['String']>;
  clinicalRationale?: Maybe<Scalars['String']>;
  moderationComment?: Maybe<Scalars['String']>;
  evalStatus?: Maybe<Scalars['String']>;
  questionnaire: Questionnaire;
  question: Question;
  value: Scalars['String'];
  comment: Scalars['String'];
  clarificationQuestions: Array<ClarificationQuestions>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  rationale?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['String']>;
};

export type ClarificationQuestions = {
  __typename?: 'ClarificationQuestions';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  updated: Scalars['DateTime'];
  datePublished?: Maybe<Scalars['DateTime']>;
  dateSubmitted?: Maybe<Scalars['DateTime']>;
  isModeration: Scalars['Boolean'];
  answer: Answer;
  body: Scalars['String'];
  answerText: Scalars['String'];
};

export type QuestionnaireStatistic = {
  __typename?: 'QuestionnaireStatistic';
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type Document = {
  __typename?: 'Document';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  id: Scalars['ID'];
  url: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
};

export type ServiceLocationMobilisation = {
  __typename?: 'ServiceLocationMobilisation';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  address: Scalars['String'];
  county?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  aqpStatus: Scalars['Boolean'];
  contractSuspended: Scalars['Boolean'];
  commissionerInfo: Scalars['String'];
  embargo: Scalars['Boolean'];
  website?: Maybe<Scalars['String']>;
  changedDetails: Scalars['String'];
  comments: Scalars['String'];
  reporting: Scalars['Boolean'];
  cqcRegistration?: Maybe<CqcRegistration>;
  phoneNumber?: Maybe<PhoneModel>;
  aqpStartDate?: Maybe<Scalars['Date']>;
  aqpEndDate?: Maybe<Scalars['Date']>;
  reportingStartDate?: Maybe<Scalars['Date']>;
  reportingEndDate?: Maybe<Scalars['Date']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  closed: Scalars['Boolean'];
  serviceUserNumber: Scalars['Int'];
  covidRestriction: Scalars['Boolean'];
  liveIn: ServiceLocationLiveIn;
  provider?: Maybe<ProviderDomCare>;
  cqcIdComment?: Maybe<Scalars['String']>;
  otherSpecialisms: Scalars['String'];
  languages: Scalars['String'];
  lastSubmittedQualityQuestionnaire?: Maybe<Scalars['Date']>;
  aqpStatus1819: Scalars['Boolean'];
  aqpStatus1920: Scalars['Boolean'];
  aqp2025StartDate?: Maybe<Scalars['DateTime']>;
  reporting2025StartDate?: Maybe<Scalars['DateTime']>;
  capacityUpdated?: Maybe<Scalars['DateTime']>;
  capacityEmailSent?: Maybe<Scalars['DateTime']>;
  servicelocationPtr: ServiceLocation;
  serviceLocation?: Maybe<ServiceLocation>;
  careTiersMobilisation: Array<CareTier>;
  liveInMobilisation: ServiceLocationMobilisationLiveInMobilisation;
  areasServedMobilisation: Array<Ccg>;
  specialismsMobilisation: Array<Specialisms>;
  languagesMobilisation: Array<LanguageSelect>;
  managerFirstNameMobilisation: Scalars['String'];
  managerLastNameMobilisation: Scalars['String'];
  jobTitle: Scalars['String'];
  emailsMobilisation: Array<EmailModel>;
  phoneNumbersMobilisation: Array<PhoneModel>;
  aqpStatusMobilisation: Scalars['Boolean'];
};

export type LettersIssuedInfo = {
  __typename?: 'LettersIssuedInfo';
  lettersIssued?: Maybe<Scalars['Boolean']>;
  lettersIssuedDate?: Maybe<Scalars['DateTime']>;
};

export type CommissionerContactInformation = {
  __typename?: 'CommissionerContactInformation';
  signerNumber?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
};

export type NoticeRequired = {
  __typename?: 'NoticeRequired';
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type DirectoryOfServicesLocation = {
  __typename?: 'DirectoryOfServicesLocation';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  uuid: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  address: Scalars['String'];
  county?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  aqpStatus: Scalars['Boolean'];
  contractSuspended: Scalars['Boolean'];
  commissionerInfo: Scalars['String'];
  embargo: Scalars['Boolean'];
  website?: Maybe<Scalars['String']>;
  changedDetails: Scalars['String'];
  comments: Scalars['String'];
  reporting: Scalars['Boolean'];
  cqcRegistration?: Maybe<CqcRegistration>;
  phoneNumber?: Maybe<PhoneModel>;
  aqpStartDate?: Maybe<Scalars['Date']>;
  aqpEndDate?: Maybe<Scalars['Date']>;
  reportingStartDate?: Maybe<Scalars['Date']>;
  reportingEndDate?: Maybe<Scalars['Date']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  closed: Scalars['Boolean'];
  serviceUserNumber: Scalars['Int'];
  serviceTypes: Array<ServiceType>;
  serviceUserBands: Array<ServiceUserBand>;
  spokenLanguages: Array<LanguageSelect>;
  covidRestriction: Scalars['Boolean'];
  id: Scalars['ID'];
  provider?: Maybe<DirectoryOfServicesProvider>;
  specialisms: Array<Specialisms>;
  noBeds?: Maybe<Scalars['Int']>;
  localAuthority?: Maybe<LocalAuthority>;
};

export type VariationAgreementResult = NhVariationAgreement | DcVariationAgreement;

export type Mutation = {
  __typename?: 'Mutation';
  addReadBy?: Maybe<AddReadBy>;
  updateCapacity?: Maybe<UpdateCapacityPayload>;
  updateLegalEntity?: Maybe<UpdateLegalEntityPayload>;
  submitMobilisationForm?: Maybe<SubmitMobilisationForm>;
  login?: Maybe<Login>;
  logout?: Maybe<Logout>;
  createUser?: Maybe<CreateUserPayload>;
  activate?: Maybe<ActivateUser>;
  resend?: Maybe<ResendActivation>;
  passwordReset?: Maybe<PasswordReset>;
  passwordChange?: Maybe<PasswordChangePayload>;
  resetPasswordConfirm?: Maybe<ResetPasswordConfirm>;
  updateDetails?: Maybe<UpdateDetailsPayload>;
  changeEmail?: Maybe<ChangeEmailPayload>;
  createAnswer?: Maybe<CreateAnswer>;
  createAnswers?: Maybe<CreateAnswers>;
  submitApplication?: Maybe<SubmitApplication>;
  messageMutation?: Maybe<MessageMutation>;
  deleteDocument?: Maybe<DeleteDocument>;
  documentMutation?: Maybe<DocumentMutationPayload>;
  readMessage?: Maybe<ReadMessage>;
  createClarificationQuestions?: Maybe<CreateClarificationQuestions>;
  publishClarificationQuestions?: Maybe<PublishClarificationQuestions>;
  submitClarificationQuestions?: Maybe<SubmitClarificationQuestions>;
  setEvalStatus?: Maybe<SetEvalStatus>;
  createTeam?: Maybe<CreateTeam>;
  createTechnicalEvaluationQuestions?: Maybe<CreateTechnicalEvaluatorRationale>;
  moderation?: Maybe<Moderation>;
  setOutcomeLetterStatus?: Maybe<SetOutcomeLetterStatus>;
  updateAqpDataMobilisation?: Maybe<UpdateServiceLocation>;
  updateAqpStatusMobilisation?: Maybe<UpdateServiceLocationStatus>;
  generateContract?: Maybe<GenerateContract>;
  issueContract?: Maybe<IssueContract>;
  issueNotEmbeddedContract?: Maybe<IssueNotEmbeddedContract>;
  updateSignatureRequest?: Maybe<UpdateSignatureRequest>;
  signContract?: Maybe<SignContract>;
  signContracts?: Maybe<SignContracts>;
  issueLetters?: Maybe<IssueLetters>;
  createLocation?: Maybe<CreateLocationPayload>;
  updateLocation?: Maybe<CreateLocationPayload>;
  createProvider?: Maybe<CreateProvider>;
  updateProviderDetails?: Maybe<ChangeProviderAccountDetails>;
  issueReminderEmail?: Maybe<IssueReminderEmail>;
  batchIssueReminderEmails?: Maybe<BatchIssueReminderEmails>;
  batchIssueVariationAgreements?: Maybe<BatchIssueVariationAgreements>;
  issueCommissionerVariationAgreement?: Maybe<IssueCommissionerVariationAgreement>;
  customReissueVariationAgreement?: Maybe<CustomReIssueVariationAgreement>;
};


export type MutationAddReadByArgs = {
  id?: Maybe<Scalars['ID']>;
  notificationId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateCapacityArgs = {
  input: UpdateCapacityInput;
};


export type MutationUpdateLegalEntityArgs = {
  input: UpdateLegalEntityInput;
};


export type MutationSubmitMobilisationFormArgs = {
  uuid?: Maybe<Scalars['ID']>;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationActivateArgs = {
  activationKey?: Maybe<Scalars['String']>;
};


export type MutationResendArgs = {
  email?: Maybe<Scalars['String']>;
};


export type MutationPasswordResetArgs = {
  email?: Maybe<Scalars['String']>;
};


export type MutationPasswordChangeArgs = {
  input: PasswordChangeInput;
};


export type MutationResetPasswordConfirmArgs = {
  newPassword?: Maybe<Scalars['String']>;
  reNewPassword?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
};


export type MutationUpdateDetailsArgs = {
  input: UpdateDetailsInput;
};


export type MutationChangeEmailArgs = {
  input: ChangeEmailInput;
};


export type MutationCreateAnswerArgs = {
  input: CreateAnswerInput;
};


export type MutationCreateAnswersArgs = {
  input: CreateAnswersInput;
};


export type MutationSubmitApplicationArgs = {
  input?: Maybe<SubmitApplicationInput>;
};


export type MutationMessageMutationArgs = {
  input?: Maybe<MessageMutationInput>;
};


export type MutationDeleteDocumentArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationDocumentMutationArgs = {
  input: DocumentMutationInput;
};


export type MutationReadMessageArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationCreateClarificationQuestionsArgs = {
  input?: Maybe<Array<Maybe<CreateClarificationQuestionInput>>>;
};


export type MutationPublishClarificationQuestionsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationSubmitClarificationQuestionsArgs = {
  uuid?: Maybe<Scalars['String']>;
};


export type MutationSetEvalStatusArgs = {
  input?: Maybe<Array<Maybe<SetEvalStatusInput>>>;
};


export type MutationCreateTeamArgs = {
  input?: Maybe<TeamInput>;
};


export type MutationCreateTechnicalEvaluationQuestionsArgs = {
  input?: Maybe<TechnicalEvaluatorListInput>;
};


export type MutationModerationArgs = {
  input?: Maybe<Array<Maybe<ModerationInput>>>;
};


export type MutationSetOutcomeLetterStatusArgs = {
  status?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};


export type MutationUpdateAqpDataMobilisationArgs = {
  input?: Maybe<UpdateServiceLocationInput>;
};


export type MutationUpdateAqpStatusMobilisationArgs = {
  serviceLocationId: Scalars['ID'];
  status: Scalars['Boolean'];
};


export type MutationGenerateContractArgs = {
  contractGenerated?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
};


export type MutationIssueContractArgs = {
  contractIssued?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
};


export type MutationIssueNotEmbeddedContractArgs = {
  contractIssuedNotEmbedded?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
};


export type MutationUpdateSignatureRequestArgs = {
  signatureId?: Maybe<Scalars['String']>;
  signatureRequestId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};


export type MutationSignContractArgs = {
  contractSigned?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
};


export type MutationSignContractsArgs = {
  signatureRequestIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationUpdateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationCreateProviderArgs = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationUpdateProviderDetailsArgs = {
  input: ChangeProviderAccountDetailsInput;
};


export type MutationIssueReminderEmailArgs = {
  agreementType?: Maybe<Scalars['String']>;
  signatureRequestId?: Maybe<Scalars['String']>;
};


export type MutationBatchIssueReminderEmailsArgs = {
  agreementType?: Maybe<Scalars['String']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  variationUuid?: Maybe<Scalars['String']>;
};


export type MutationBatchIssueVariationAgreementsArgs = {
  agreementType?: Maybe<Scalars['String']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  variationUuid?: Maybe<Scalars['String']>;
};


export type MutationIssueCommissionerVariationAgreementArgs = {
  agreementType?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type MutationCustomReissueVariationAgreementArgs = {
  agreementType?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  signatureRequestId?: Maybe<Scalars['String']>;
};

export type AddReadBy = {
  __typename?: 'AddReadBy';
  notification?: Maybe<Notification>;
};

export type UpdateCapacityPayload = {
  __typename?: 'UpdateCapacityPayload';
  id?: Maybe<Scalars['Int']>;
  serviceLocation?: Maybe<ServiceLocation>;
  noticeRequiredDisplay?: Maybe<Scalars['String']>;
  minimumCallDurationDisplay?: Maybe<Scalars['String']>;
  areas?: Maybe<Array<Maybe<LocalAuthority>>>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  minimumCallDuration?: Maybe<Scalars['String']>;
  noticeRequired?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  acceptsCovid?: Maybe<Scalars['String']>;
  acceptsCovidComment?: Maybe<Scalars['String']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ErrorType = {
  __typename?: 'ErrorType';
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

export type UpdateCapacityInput = {
  serviceLocationId: Scalars['String'];
  areas: Array<Maybe<Scalars['String']>>;
  minimumCallDuration: Scalars['String'];
  noticeRequired?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  acceptsCovid?: Maybe<Scalars['String']>;
  acceptsCovidComment?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLegalEntityPayload = {
  __typename?: 'UpdateLegalEntityPayload';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  dateCompleted?: Maybe<Scalars['DateTime']>;
  registeredOfficeAddress?: Maybe<Scalars['String']>;
  companyCharityNumber?: Maybe<Scalars['String']>;
  representativeName?: Maybe<Scalars['String']>;
  representativeAddress?: Maybe<Scalars['String']>;
  representativeRole?: Maybe<Scalars['String']>;
  representativeEmail?: Maybe<Scalars['String']>;
  representativeTelephone?: Maybe<Scalars['String']>;
  informationGovernanceLead?: Maybe<Scalars['String']>;
  caldicottGuardian?: Maybe<Scalars['String']>;
  nominatedIndividual?: Maybe<Scalars['String']>;
  informationRiskOwner?: Maybe<Scalars['String']>;
  preventLead?: Maybe<Scalars['String']>;
  accountableEmergencyOfficer?: Maybe<Scalars['String']>;
  safeguardingLead?: Maybe<Scalars['String']>;
  abuseLead?: Maybe<Scalars['String']>;
  mentalAndDolLead?: Maybe<Scalars['String']>;
  freedomToSpeakUpGuardian?: Maybe<Scalars['String']>;
  dataProtectionOfficer?: Maybe<Scalars['String']>;
  subcontractingDetails?: Maybe<Scalars['String']>;
  signatoryName?: Maybe<Scalars['String']>;
  signatoryJobTitle?: Maybe<Scalars['String']>;
  employersLiability?: Maybe<Scalars['String']>;
  publicLiability?: Maybe<Scalars['String']>;
  professionalIdemnity?: Maybe<Scalars['String']>;
  serviceAgreement?: Maybe<Scalars['String']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLegalEntityInput = {
  id?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
  registeredOfficeAddress?: Maybe<Scalars['String']>;
  companyCharityNumber?: Maybe<Scalars['String']>;
  representativeName?: Maybe<Scalars['String']>;
  representativeAddress?: Maybe<Scalars['String']>;
  representativeRole?: Maybe<Scalars['String']>;
  representativeEmail?: Maybe<Scalars['String']>;
  representativeTelephone?: Maybe<Scalars['String']>;
  informationGovernanceLead?: Maybe<Scalars['String']>;
  caldicottGuardian?: Maybe<Scalars['String']>;
  nominatedIndividual?: Maybe<Scalars['String']>;
  informationRiskOwner?: Maybe<Scalars['String']>;
  preventLead?: Maybe<Scalars['String']>;
  accountableEmergencyOfficer?: Maybe<Scalars['String']>;
  safeguardingLead?: Maybe<Scalars['String']>;
  abuseLead?: Maybe<Scalars['String']>;
  mentalAndDolLead?: Maybe<Scalars['String']>;
  freedomToSpeakUpGuardian?: Maybe<Scalars['String']>;
  dataProtectionOfficer?: Maybe<Scalars['String']>;
  subcontractingDetails?: Maybe<Scalars['String']>;
  signatoryName?: Maybe<Scalars['String']>;
  signatoryJobTitle?: Maybe<Scalars['String']>;
  employersLiability?: Maybe<Scalars['String']>;
  publicLiability?: Maybe<Scalars['String']>;
  professionalIdemnity?: Maybe<Scalars['String']>;
  serviceAgreement?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SubmitMobilisationForm = {
  __typename?: 'SubmitMobilisationForm';
  entity?: Maybe<LegalEntity2021>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type Login = {
  __typename?: 'Login';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  token?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Logout = {
  __typename?: 'Logout';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  registration?: Maybe<ProcurementUserRegistration>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  activationKey?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ProcurementUserRegistration = {
  __typename?: 'ProcurementUserRegistration';
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  id: Scalars['ID'];
  isRemoved: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  applicationName: Scalars['String'];
  email: Scalars['String'];
};

export type CreateUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  applicationName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ActivateUser = {
  __typename?: 'ActivateUser';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type ResendActivation = {
  __typename?: 'ResendActivation';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type PasswordReset = {
  __typename?: 'PasswordReset';
  resetLink?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type PasswordChangePayload = {
  __typename?: 'PasswordChangePayload';
  oldPassword: Scalars['String'];
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PasswordChangeInput = {
  oldPassword: Scalars['String'];
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResetPasswordConfirm = {
  __typename?: 'ResetPasswordConfirm';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateDetailsPayload = {
  __typename?: 'UpdateDetailsPayload';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDetailsInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChangeEmailPayload = {
  __typename?: 'ChangeEmailPayload';
  email: Scalars['String'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChangeEmailInput = {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAnswer = {
  __typename?: 'CreateAnswer';
  answer?: Maybe<Answer>;
};

export type CreateAnswerInput = {
  questionnaireId?: Maybe<Scalars['ID']>;
  questionId?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type CreateAnswers = {
  __typename?: 'CreateAnswers';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  answers?: Maybe<Array<Maybe<Answer>>>;
};

export type CreateAnswersInput = {
  answers?: Maybe<Array<Maybe<CreateAnswerInput>>>;
};

export type SubmitApplication = {
  __typename?: 'SubmitApplication';
  questionnaire?: Maybe<Questionnaire>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type SubmitApplicationInput = {
  id?: Maybe<Scalars['ID']>;
};

export type MessageMutation = {
  __typename?: 'MessageMutation';
  message?: Maybe<Message>;
};

export type MessageMutationInput = {
  attachment?: Maybe<MessageAttachmentInput>;
  sendTo?: Maybe<Scalars['String']>;
  thread?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type MessageAttachmentInput = {
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type DeleteDocument = {
  __typename?: 'DeleteDocument';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type DocumentMutationPayload = {
  __typename?: 'DocumentMutationPayload';
  id?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DocumentMutationInput = {
  url: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ReadMessage = {
  __typename?: 'ReadMessage';
  thread?: Maybe<Thread>;
};

export type CreateClarificationQuestions = {
  __typename?: 'CreateClarificationQuestions';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clarificationQuestions?: Maybe<Array<Maybe<ClarificationQuestion>>>;
};

export type ClarificationQuestion = {
  __typename?: 'ClarificationQuestion';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  updated: Scalars['DateTime'];
  datePublished?: Maybe<Scalars['DateTime']>;
  dateSubmitted?: Maybe<Scalars['DateTime']>;
  isModeration: Scalars['Boolean'];
  answer: Answer;
  body: Scalars['String'];
  answerText: Scalars['String'];
};

export type CreateClarificationQuestionInput = {
  answerId: Scalars['ID'];
  body?: Maybe<Scalars['String']>;
  answerText?: Maybe<Scalars['String']>;
};

export type PublishClarificationQuestions = {
  __typename?: 'PublishClarificationQuestions';
  clarificationQuestions?: Maybe<Array<Maybe<ClarificationQuestion>>>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type SubmitClarificationQuestions = {
  __typename?: 'SubmitClarificationQuestions';
  submittedClarificationQuestions?: Maybe<Array<Maybe<ClarificationQuestion>>>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type SetEvalStatus = {
  __typename?: 'SetEvalStatus';
  answer?: Maybe<Array<Maybe<Answer>>>;
};

export type SetEvalStatusInput = {
  answerId: Scalars['ID'];
  setStatus?: Maybe<Scalars['String']>;
};

export type CreateTeam = {
  __typename?: 'CreateTeam';
  teTeam?: Maybe<TeTeam>;
};

export type TeamInput = {
  applicationIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  teamMemberIds?: Maybe<Array<Maybe<EvaluatorInput>>>;
  name?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
};

export type EvaluatorInput = {
  id?: Maybe<Scalars['ID']>;
  evaluatorType?: Maybe<Scalars['String']>;
};

export type CreateTechnicalEvaluatorRationale = {
  __typename?: 'CreateTechnicalEvaluatorRationale';
  answers?: Maybe<Array<Maybe<Answer>>>;
};

export type TechnicalEvaluatorListInput = {
  outcomes?: Maybe<Array<Maybe<TechnicalEvaluatorRationaleInput>>>;
};

export type TechnicalEvaluatorRationaleInput = {
  answerId?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['String']>;
  rationale?: Maybe<Scalars['String']>;
};

export type Moderation = {
  __typename?: 'Moderation';
  answers?: Maybe<Array<Maybe<Answer>>>;
};

export type ModerationInput = {
  answerId?: Maybe<Scalars['ID']>;
  outcome?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type SetOutcomeLetterStatus = {
  __typename?: 'SetOutcomeLetterStatus';
  questionnaire?: Maybe<Questionnaire>;
};

export type UpdateServiceLocation = {
  __typename?: 'UpdateServiceLocation';
  serviceLocationMobilisation?: Maybe<ServiceLocationMobilisations>;
};

export type UpdateServiceLocationInput = {
  serviceLocationId: Scalars['String'];
  careTiers: Array<Maybe<Scalars['String']>>;
  liveInCare: Scalars['String'];
  areasCovered: Array<Maybe<Scalars['String']>>;
  specialisms: Array<Maybe<Scalars['String']>>;
  languages: Array<Maybe<Scalars['String']>>;
  managerFirstName: Scalars['String'];
  managerLastName: Scalars['String'];
  jobTitle: Scalars['String'];
  email: Array<Maybe<Scalars['String']>>;
  phone: Array<Maybe<Scalars['String']>>;
};

export type UpdateServiceLocationStatus = {
  __typename?: 'UpdateServiceLocationStatus';
  serviceLocationMobilisation?: Maybe<ServiceLocationMobilisations>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type GenerateContract = {
  __typename?: 'GenerateContract';
  questionnaire?: Maybe<Questionnaire>;
};

export type IssueContract = {
  __typename?: 'IssueContract';
  questionnaire?: Maybe<Questionnaire>;
};

export type IssueNotEmbeddedContract = {
  __typename?: 'IssueNotEmbeddedContract';
  questionnaire?: Maybe<Questionnaire>;
};

export type UpdateSignatureRequest = {
  __typename?: 'UpdateSignatureRequest';
  questionnaire?: Maybe<Questionnaire>;
};

export type SignContract = {
  __typename?: 'SignContract';
  questionnaire?: Maybe<Questionnaire>;
};

export type SignContracts = {
  __typename?: 'SignContracts';
  questionnaires?: Maybe<Array<Maybe<Questionnaire>>>;
};

export type IssueLetters = {
  __typename?: 'IssueLetters';
  issued?: Maybe<Scalars['Boolean']>;
};

export type CreateLocationPayload = {
  __typename?: 'CreateLocationPayload';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  cqcRegistration?: Maybe<CqcRegistration>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLocationInput = {
  name: Scalars['String'];
  cqcRegistration?: Maybe<CqcRegistrationSerializerInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CqcRegistrationSerializerInput = {
  cqcRegistrationStatus?: Maybe<Scalars['String']>;
  recordedId: Scalars['String'];
  lastReportPublicationDate?: Maybe<Scalars['Date']>;
  cqcLink?: Maybe<Scalars['String']>;
  rating?: Maybe<CqcRatingSerializerInput>;
  regulatedActivities?: Maybe<Array<Maybe<CqcActivitiesSerializerInput>>>;
};

export type CqcRatingSerializerInput = {
  overall?: Maybe<Scalars['String']>;
  safe?: Maybe<Scalars['String']>;
  effective?: Maybe<Scalars['String']>;
  caring?: Maybe<Scalars['String']>;
  responsive?: Maybe<Scalars['String']>;
  wellLed?: Maybe<Scalars['String']>;
};

export type CqcActivitiesSerializerInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type CreateProvider = {
  __typename?: 'CreateProvider';
  provider?: Maybe<ProviderUserProfileType>;
};

export type ChangeProviderAccountDetails = {
  __typename?: 'ChangeProviderAccountDetails';
  provider?: Maybe<ProviderUserProfileType>;
};

export type ChangeProviderAccountDetailsInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type IssueReminderEmail = {
  __typename?: 'IssueReminderEmail';
  ok?: Maybe<Scalars['Boolean']>;
  providerName?: Maybe<Scalars['String']>;
};

export type BatchIssueReminderEmails = {
  __typename?: 'BatchIssueReminderEmails';
  ok?: Maybe<Scalars['Boolean']>;
};

export type BatchIssueVariationAgreements = {
  __typename?: 'BatchIssueVariationAgreements';
  ok?: Maybe<Scalars['Boolean']>;
};

export type IssueCommissionerVariationAgreement = {
  __typename?: 'IssueCommissionerVariationAgreement';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CustomReIssueVariationAgreement = {
  __typename?: 'CustomReIssueVariationAgreement';
  providerName?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type ViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'username' | 'email' | 'isActive' | 'lph'>
    & { userProfile?: Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'isEvaluator'>
      & { provideruserprofile?: Maybe<(
        { __typename?: 'ProviderUserProfileType' }
        & { domcareProcurement?: Maybe<(
          { __typename?: 'ProcurementProvider' }
          & Pick<ProcurementProvider, 'applicationName'>
          & { qualificationquestionnaire?: Maybe<(
            { __typename?: 'Questionnaire' }
            & Pick<Questionnaire, 'id' | 'uuid' | 'letterIssued' | 'outcomeLetterStatus' | 'contractIssued' | 'contractSigned' | 'signatureId'>
          )>, legalEntity2021?: Maybe<(
            { __typename?: 'LegalEntity2021' }
            & Pick<LegalEntity2021, 'uuid' | 'name'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type PasswordChangeMutationVariables = Exact<{
  input: PasswordChangeInput;
}>;


export type PasswordChangeMutation = (
  { __typename?: 'Mutation' }
  & { passwordChange?: Maybe<(
    { __typename?: 'PasswordChangePayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdateDetailsMutationVariables = Exact<{
  input: UpdateDetailsInput;
}>;


export type UpdateDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updateDetails?: Maybe<(
    { __typename?: 'UpdateDetailsPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type ChangeEmailMutationVariables = Exact<{
  input: ChangeEmailInput;
}>;


export type ChangeEmailMutation = (
  { __typename?: 'Mutation' }
  & { changeEmail?: Maybe<(
    { __typename?: 'ChangeEmailPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'Login' }
    & Pick<Login, 'token'>
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout?: Maybe<(
    { __typename?: 'Logout' }
    & Pick<Logout, 'success'>
  )> }
);

export type ActivateUserMutationVariables = Exact<{
  activationKey: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & { activate?: Maybe<(
    { __typename?: 'ActivateUser' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type ResendActivationMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResendActivationMutation = (
  { __typename?: 'Mutation' }
  & { resend?: Maybe<(
    { __typename?: 'ResendActivation' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type PasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type PasswordResetMutation = (
  { __typename?: 'Mutation' }
  & { passwordReset?: Maybe<(
    { __typename?: 'PasswordReset' }
    & Pick<PasswordReset, 'resetLink'>
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type ResetPasswordConfirmMutationVariables = Exact<{
  uid: Scalars['String'];
  token: Scalars['String'];
  newPassword: Scalars['String'];
  reNewPassword: Scalars['String'];
}>;


export type ResetPasswordConfirmMutation = (
  { __typename?: 'Mutation' }
  & { resetPasswordConfirm?: Maybe<(
    { __typename?: 'ResetPasswordConfirm' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'CreateUserPayload' }
    & Pick<CreateUserPayload, 'activationKey' | 'token'>
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>>, registration?: Maybe<(
      { __typename?: 'ProcurementUserRegistration' }
      & Pick<ProcurementUserRegistration, 'firstName' | 'lastName' | 'applicationName' | 'email'>
    )> }
  )> }
);

export type SectionQueryVariables = Exact<{
  uuid: Scalars['String'];
  order: Scalars['Float'];
}>;


export type SectionQuery = (
  { __typename?: 'Query' }
  & { section?: Maybe<(
    { __typename?: 'Section' }
    & Pick<Section, 'id' | 'order' | 'title'>
    & { categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'order' | 'title' | 'details'>
      & { questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'order' | 'type' | 'title' | 'choices' | 'details' | 'mandatory' | 'notApplicable' | 'commentBox' | 'charLimit'>
        & { answers: Array<(
          { __typename?: 'Answer' }
          & Pick<Answer, 'id' | 'value' | 'evalStatus' | 'values' | 'comment' | 'outcome' | 'rationale' | 'commissioningOutcome' | 'clinicalOutcome' | 'commissioningRationale' | 'clinicalRationale' | 'moderationComment' | 'moderationOutcome'>
          & { clarificationQuestions: Array<(
            { __typename?: 'ClarificationQuestions' }
            & Pick<ClarificationQuestions, 'id' | 'body' | 'answerText' | 'datePublished' | 'dateSubmitted'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type CreateAnswerMutationVariables = Exact<{
  input: CreateAnswerInput;
}>;


export type CreateAnswerMutation = (
  { __typename?: 'Mutation' }
  & { createAnswer?: Maybe<(
    { __typename?: 'CreateAnswer' }
    & { answer?: Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'value' | 'values' | 'comment'>
    )> }
  )> }
);

export type EvaluatorTeamQueryVariables = Exact<{
  username?: Maybe<Scalars['String']>;
}>;


export type EvaluatorTeamQuery = (
  { __typename?: 'Query' }
  & { team?: Maybe<(
    { __typename?: 'TETeam' }
    & { applications: Array<(
      { __typename?: 'ProcurementProvider' }
      & Pick<ProcurementProvider, 'id' | 'applicationName'>
      & { qualificationquestionnaire?: Maybe<(
        { __typename?: 'Questionnaire' }
        & Pick<Questionnaire, 'uuid' | 'evaluatorStatus' | 'technicalEvaluatorProgress' | 'technicalClarificationProgress'>
      )> }
    )> }
  )> }
);

export type CreateTechnicalEvaluationQuestionMutationVariables = Exact<{
  input: TechnicalEvaluatorListInput;
}>;


export type CreateTechnicalEvaluationQuestionMutation = (
  { __typename?: 'Mutation' }
  & { createTechnicalEvaluationQuestions?: Maybe<(
    { __typename?: 'CreateTechnicalEvaluatorRationale' }
    & { answers?: Maybe<Array<Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'value' | 'outcome' | 'rationale'>
    )>>> }
  )> }
);

export type SectionListQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type SectionListQuery = (
  { __typename?: 'Query' }
  & { questionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'uuid' | 'lastSave' | 'dateCompleted' | 'clarificationQuestionsDeadline' | 'clarificationQuestionsSubmittedDate' | 'clarificationQuestionsPending' | 'readOnly' | 'technicalEvaluatorProgress'>
    & { sections?: Maybe<Array<Maybe<(
      { __typename?: 'Section' }
      & Pick<Section, 'id' | 'order' | 'title' | 'status' | 'cqStatus' | 'cqDescription' | 'complianceStatus' | 'description'>
    )>>>, user?: Maybe<(
      { __typename?: 'ProcurementProvider' }
      & Pick<ProcurementProvider, 'id' | 'applicationName'>
      & { evaluatorTeam?: Maybe<(
        { __typename?: 'TETeam' }
        & Pick<TeTeam, 'name'>
      )> }
    )> }
  )> }
);

export type SectionsExportQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type SectionsExportQuery = (
  { __typename?: 'Query' }
  & { sectionsExport?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'uuid' | 'lastSave' | 'dateCompleted' | 'readOnly'>
    & { sections?: Maybe<Array<Maybe<(
      { __typename?: 'Section' }
      & Pick<Section, 'id' | 'order' | 'title' | 'status' | 'description'>
      & { categories: Array<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'order' | 'title' | 'details'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'order' | 'type' | 'title' | 'choices' | 'details' | 'mandatory' | 'notApplicable' | 'commentBox' | 'charLimit'>
          & { answers: Array<(
            { __typename?: 'Answer' }
            & Pick<Answer, 'value' | 'values' | 'comment'>
            & { clarificationQuestions: Array<(
              { __typename?: 'ClarificationQuestions' }
              & Pick<ClarificationQuestions, 'id' | 'body' | 'answerText' | 'datePublished' | 'dateSubmitted'>
            )> }
          )> }
        )> }
      )> }
    )>>>, user?: Maybe<(
      { __typename?: 'ProcurementProvider' }
      & Pick<ProcurementProvider, 'id' | 'applicationName'>
      & { provideruserprofile?: Maybe<(
        { __typename?: 'ProviderUserProfileType' }
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'fullName' | 'email'>
        ) }
      )> }
    )> }
  )> }
);

export type CreateAnswersMutationVariables = Exact<{
  input: CreateAnswersInput;
}>;


export type CreateAnswersMutation = (
  { __typename?: 'Mutation' }
  & { createAnswers?: Maybe<(
    { __typename?: 'CreateAnswers' }
    & { answers?: Maybe<Array<Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'value' | 'values' | 'comment'>
    )>>>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type SubmitApplicationMutationVariables = Exact<{
  input: SubmitApplicationInput;
}>;


export type SubmitApplicationMutation = (
  { __typename?: 'Mutation' }
  & { submitApplication?: Maybe<(
    { __typename?: 'SubmitApplication' }
    & { questionnaire?: Maybe<(
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'id' | 'dateCompleted'>
    )> }
  )> }
);

export type DocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type DocumentsQuery = (
  { __typename?: 'Query' }
  & { documents?: Maybe<Array<Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'name' | 'description' | 'url' | 'created'>
  )>>> }
);

export type DocumentMutationVariables = Exact<{
  input: DocumentMutationInput;
}>;


export type DocumentMutation = (
  { __typename?: 'Mutation' }
  & { documentMutation?: Maybe<(
    { __typename?: 'DocumentMutationPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDocumentMutation = (
  { __typename?: 'Mutation' }
  & { deleteDocument?: Maybe<(
    { __typename?: 'DeleteDocument' }
    & Pick<DeleteDocument, 'deleted'>
  )> }
);

export type UnreadMessagesQueryVariables = Exact<{ [key: string]: never; }>;


export type UnreadMessagesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'unreadMessagesCount'>
);

export type ThreadsQueryVariables = Exact<{
  read?: Maybe<Scalars['Boolean']>;
  audience?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
}>;


export type ThreadsQuery = (
  { __typename?: 'Query' }
  & { threads?: Maybe<(
    { __typename?: 'ThreadConnection' }
    & Pick<ThreadConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'ThreadEdge' }
      & Pick<ThreadEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Thread' }
        & Pick<Thread, 'id'>
        & { sendTo?: Maybe<(
          { __typename?: 'ProcurementProvider' }
          & Pick<ProcurementProvider, 'id'>
          & { provideruserprofile?: Maybe<(
            { __typename?: 'ProviderUserProfileType' }
            & { user: (
              { __typename?: 'User' }
              & Pick<User, 'id' | 'firstName'>
            ) }
          )> }
        )>, messages: Array<(
          { __typename?: 'Message' }
          & Pick<Message, 'id' | 'subject' | 'read' | 'created' | 'audience' | 'body'>
          & { sender?: Maybe<(
            { __typename?: 'ProcurementProvider' }
            & Pick<ProcurementProvider, 'id'>
            & { provideruserprofile?: Maybe<(
              { __typename?: 'ProviderUserProfileType' }
              & { user: (
                { __typename?: 'User' }
                & Pick<User, 'id' | 'fullName'>
              ) }
            )> }
          )>, attachment?: Maybe<(
            { __typename?: 'MessageAttachment' }
            & Pick<MessageAttachment, 'id' | 'name' | 'url'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type CreateMessageMutationVariables = Exact<{
  input: MessageMutationInput;
}>;


export type CreateMessageMutation = (
  { __typename?: 'Mutation' }
  & { messageMutation?: Maybe<(
    { __typename?: 'MessageMutation' }
    & { message?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'subject' | 'body'>
    )> }
  )> }
);

export type ProcurementUserProfilesQueryVariables = Exact<{
  complete?: Maybe<Scalars['Boolean']>;
}>;


export type ProcurementUserProfilesQuery = (
  { __typename?: 'Query' }
  & { procurementUserProfiles?: Maybe<Array<Maybe<(
    { __typename?: 'ProcurementProvider' }
    & Pick<ProcurementProvider, 'id' | 'applicationName'>
    & { evaluatorTeam?: Maybe<(
      { __typename?: 'TETeam' }
      & Pick<TeTeam, 'id'>
    )> }
  )>>> }
);

export type ReadMessageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReadMessageMutation = (
  { __typename?: 'Mutation' }
  & { readMessage?: Maybe<(
    { __typename?: 'ReadMessage' }
    & { thread?: Maybe<(
      { __typename?: 'Thread' }
      & Pick<Thread, 'id'>
      & { sendTo?: Maybe<(
        { __typename?: 'ProcurementProvider' }
        & Pick<ProcurementProvider, 'id'>
        & { provideruserprofile?: Maybe<(
          { __typename?: 'ProviderUserProfileType' }
          & { user: (
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName'>
          ) }
        )> }
      )>, messages: Array<(
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'subject' | 'read' | 'created' | 'audience' | 'body'>
        & { sender?: Maybe<(
          { __typename?: 'ProcurementProvider' }
          & Pick<ProcurementProvider, 'id'>
          & { provideruserprofile?: Maybe<(
            { __typename?: 'ProviderUserProfileType' }
            & { user: (
              { __typename?: 'User' }
              & Pick<User, 'id' | 'fullName'>
            ) }
          )> }
        )>, attachment?: Maybe<(
          { __typename?: 'MessageAttachment' }
          & Pick<MessageAttachment, 'id' | 'name' | 'url'>
        )> }
      )> }
    )> }
  )> }
);

export type QuestionnairesQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type QuestionnairesQuery = (
  { __typename?: 'Query' }
  & { questionnaires?: Maybe<(
    { __typename?: 'QuestionnaireConnection' }
    & Pick<QuestionnaireConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'QuestionnaireEdge' }
      & Pick<QuestionnaireEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Questionnaire' }
        & Pick<Questionnaire, 'id' | 'uuid' | 'created' | 'status' | 'ccStatus' | 'feStatus'>
        & { user?: Maybe<(
          { __typename?: 'ProcurementProvider' }
          & Pick<ProcurementProvider, 'applicationName'>
          & { provideruserprofile?: Maybe<(
            { __typename?: 'ProviderUserProfileType' }
            & { user: (
              { __typename?: 'User' }
              & Pick<User, 'id' | 'fullName' | 'email'>
            ) }
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type QuestionnairesExportQueryVariables = Exact<{
  outcome: Scalars['String'];
}>;


export type QuestionnairesExportQuery = (
  { __typename?: 'Query' }
  & { questionnairesExport?: Maybe<Array<Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'uuid'>
  )>>> }
);

export type EvaluationTableQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  ccStatus?: Maybe<Scalars['String']>;
  teStatus?: Maybe<Scalars['String']>;
  feStatus?: Maybe<Scalars['String']>;
  overallStatus?: Maybe<Scalars['String']>;
}>;


export type EvaluationTableQuery = (
  { __typename?: 'Query' }
  & { questionnaires?: Maybe<(
    { __typename?: 'QuestionnaireConnection' }
    & Pick<QuestionnaireConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'QuestionnaireEdge' }
      & Pick<QuestionnaireEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Questionnaire' }
        & Pick<Questionnaire, 'id' | 'uuid' | 'ccStatus' | 'feStatus' | 'teStatus' | 'overallStatus' | 'outcomeLetterStatus'>
        & { user?: Maybe<(
          { __typename?: 'ProcurementProvider' }
          & Pick<ProcurementProvider, 'applicationName'>
        )> }
      )> }
    )>> }
  )> }
);

export type MobilisationTableQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  ccStatus?: Maybe<Scalars['String']>;
  teStatus?: Maybe<Scalars['String']>;
  feStatus?: Maybe<Scalars['String']>;
  overallStatus?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['String']>;
  mobilisationStatus?: Maybe<Scalars['String']>;
  contractSigned?: Maybe<Scalars['String']>;
}>;


export type MobilisationTableQuery = (
  { __typename?: 'Query' }
  & { questionnaires?: Maybe<(
    { __typename?: 'QuestionnaireConnection' }
    & Pick<QuestionnaireConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'QuestionnaireEdge' }
      & Pick<QuestionnaireEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Questionnaire' }
        & Pick<Questionnaire, 'id' | 'uuid' | 'contractGenerated' | 'contractIssued' | 'contractIssuedNotEmbedded' | 'contractSigned' | 'signatureRequestId' | 'mobilisationStatus' | 'outcomeLetterStatus' | 'overallStatus'>
        & { user?: Maybe<(
          { __typename?: 'ProcurementProvider' }
          & Pick<ProcurementProvider, 'applicationName'>
          & { legalEntity2021?: Maybe<(
            { __typename?: 'LegalEntity2021' }
            & Pick<LegalEntity2021, 'uuid' | 'name' | 'representativeName' | 'representativeEmail' | 'signatoryName'>
          )>, provideruserprofile?: Maybe<(
            { __typename?: 'ProviderUserProfileType' }
            & { user: (
              { __typename?: 'User' }
              & Pick<User, 'id' | 'fullName' | 'email'>
            ) }
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type EvaluationStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type EvaluationStatisticsQuery = (
  { __typename?: 'Query' }
  & { evaluationStatistics?: Maybe<Array<Maybe<(
    { __typename?: 'QuestionnaireStatistic' }
    & Pick<QuestionnaireStatistic, 'title' | 'value'>
  )>>> }
);

export type QuestionnaireStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type QuestionnaireStatisticsQuery = (
  { __typename?: 'Query' }
  & { questionnaireStatistics?: Maybe<Array<Maybe<(
    { __typename?: 'QuestionnaireStatistic' }
    & Pick<QuestionnaireStatistic, 'title' | 'value'>
  )>>> }
);

export type TeamQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamQuery = (
  { __typename?: 'Query' }
  & { teams?: Maybe<Array<Maybe<(
    { __typename?: 'TETeam' }
    & Pick<TeTeam, 'id' | 'name'>
    & { applications: Array<(
      { __typename?: 'ProcurementProvider' }
      & Pick<ProcurementProvider, 'id' | 'applicationName'>
      & { qualificationquestionnaire?: Maybe<(
        { __typename?: 'Questionnaire' }
        & Pick<Questionnaire, 'uuid' | 'clinicalStatus' | 'commissioningStatus'>
      )> }
    )>, evaluatorSet?: Maybe<Array<Maybe<(
      { __typename?: 'Evaluator' }
      & Pick<Evaluator, 'evaluatorType'>
      & { evaluator?: Maybe<(
        { __typename?: 'EvaluatorProfile' }
        & Pick<EvaluatorProfile, 'id'>
      )> }
    )>>> }
  )>>> }
);

export type EvaluatorsQueryVariables = Exact<{ [key: string]: never; }>;


export type EvaluatorsQuery = (
  { __typename?: 'Query' }
  & { userProfiles?: Maybe<Array<Maybe<(
    { __typename?: 'EvaluatorProfile' }
    & Pick<EvaluatorProfile, 'id'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username' | 'firstName' | 'lastName'>
    )> }
  )>>> }
);

export type CreateTeamMutationVariables = Exact<{
  input?: Maybe<TeamInput>;
}>;


export type CreateTeamMutation = (
  { __typename?: 'Mutation' }
  & { createTeam?: Maybe<(
    { __typename?: 'CreateTeam' }
    & { teTeam?: Maybe<(
      { __typename?: 'TETeam' }
      & Pick<TeTeam, 'name'>
      & { applications: Array<(
        { __typename?: 'ProcurementProvider' }
        & Pick<ProcurementProvider, 'id' | 'applicationName'>
      )>, evaluatorSet?: Maybe<Array<Maybe<(
        { __typename?: 'Evaluator' }
        & Pick<Evaluator, 'evaluatorTypeDisplay'>
        & { evaluator?: Maybe<(
          { __typename?: 'EvaluatorProfile' }
          & Pick<EvaluatorProfile, 'id'>
        )> }
      )>>> }
    )> }
  )> }
);

export type CreateClarificationQuestionMutationVariables = Exact<{
  input: Array<Maybe<CreateClarificationQuestionInput>>;
}>;


export type CreateClarificationQuestionMutation = (
  { __typename?: 'Mutation' }
  & { createClarificationQuestions?: Maybe<(
    { __typename?: 'CreateClarificationQuestions' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>>, clarificationQuestions?: Maybe<Array<Maybe<(
      { __typename?: 'ClarificationQuestion' }
      & Pick<ClarificationQuestion, 'id' | 'body' | 'answerText' | 'datePublished' | 'dateSubmitted'>
      & { answer: (
        { __typename?: 'Answer' }
        & Pick<Answer, 'id' | 'evalStatus'>
      ) }
    )>>> }
  )> }
);

export type ClarificationQuestionsQueryVariables = Exact<{
  qid?: Maybe<Scalars['String']>;
}>;


export type ClarificationQuestionsQuery = (
  { __typename?: 'Query' }
  & { clarificationQuestion?: Maybe<Array<Maybe<(
    { __typename?: 'ClarificationQuestions' }
    & Pick<ClarificationQuestions, 'id' | 'datePublished' | 'dateSubmitted' | 'body' | 'answerText'>
    & { answer: (
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'evalStatus'>
    ) }
  )>>> }
);

export type ClarificationListQueryVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
  isModeration?: Maybe<Scalars['Boolean']>;
}>;


export type ClarificationListQuery = (
  { __typename?: 'Query' }
  & { clarificationQuestions?: Maybe<Array<Maybe<(
    { __typename?: 'ClarificationQuestions' }
    & Pick<ClarificationQuestions, 'id' | 'body' | 'answerText'>
    & { answer: (
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'value' | 'values'>
      & { question: (
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'order'>
        & { category: (
          { __typename?: 'Category' }
          & Pick<Category, 'id' | 'order'>
          & { section: (
            { __typename?: 'Section' }
            & Pick<Section, 'id' | 'order'>
          ) }
        ) }
      ) }
    ) }
  )>>> }
);

export type PublishClarificationQuestionsMutationVariables = Exact<{
  ids: Array<Maybe<Scalars['ID']>>;
}>;


export type PublishClarificationQuestionsMutation = (
  { __typename?: 'Mutation' }
  & { publishClarificationQuestions?: Maybe<(
    { __typename?: 'PublishClarificationQuestions' }
    & { clarificationQuestions?: Maybe<Array<Maybe<(
      { __typename?: 'ClarificationQuestion' }
      & Pick<ClarificationQuestion, 'body' | 'id' | 'datePublished'>
      & { answer: (
        { __typename?: 'Answer' }
        & Pick<Answer, 'id'>
      ) }
    )>>>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type SubmitClarificationQuestionsMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type SubmitClarificationQuestionsMutation = (
  { __typename?: 'Mutation' }
  & { submitClarificationQuestions?: Maybe<(
    { __typename?: 'SubmitClarificationQuestions' }
    & { submittedClarificationQuestions?: Maybe<Array<Maybe<(
      { __typename?: 'ClarificationQuestion' }
      & Pick<ClarificationQuestion, 'id' | 'body' | 'answerText' | 'datePublished' | 'dateSubmitted'>
    )>>>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type SetStatusMutationVariables = Exact<{
  input?: Maybe<Array<Maybe<SetEvalStatusInput>>>;
}>;


export type SetStatusMutation = (
  { __typename?: 'Mutation' }
  & { setEvalStatus?: Maybe<(
    { __typename?: 'SetEvalStatus' }
    & { answer?: Maybe<Array<Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'evalStatus'>
      & { clarificationQuestions: Array<(
        { __typename?: 'ClarificationQuestions' }
        & Pick<ClarificationQuestions, 'datePublished' | 'dateSubmitted' | 'body' | 'answerText' | 'id'>
      )> }
    )>>> }
  )> }
);

export type ModerationMutationVariables = Exact<{
  input: Array<Maybe<ModerationInput>>;
}>;


export type ModerationMutation = (
  { __typename?: 'Mutation' }
  & { moderation?: Maybe<(
    { __typename?: 'Moderation' }
    & { answers?: Maybe<Array<Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'moderationOutcome' | 'moderationComment'>
    )>>> }
  )> }
);

export type MobilisationFormQueryVariables = Exact<{
  uuid?: Maybe<Scalars['ID']>;
}>;


export type MobilisationFormQuery = (
  { __typename?: 'Query' }
  & { mobilisation2021?: Maybe<(
    { __typename?: 'LegalEntity2021' }
    & Pick<LegalEntity2021, 'id' | 'name' | 'preventLead' | 'representativeName' | 'representativeAddress' | 'representativeEmail' | 'representativeTelephone' | 'representativeRole' | 'nominatedIndividual' | 'informationGovernanceLead' | 'informationRiskOwner' | 'caldicottGuardian' | 'companyCharityNumber' | 'accountableEmergencyOfficer' | 'safeguardingLead' | 'mentalAndDolLead' | 'freedomToSpeakUpGuardian' | 'dataProtectionOfficer' | 'subcontractingDetails' | 'signatoryName' | 'signatoryJobTitle' | 'abuseLead' | 'registeredOfficeAddress' | 'employersLiability' | 'publicLiability' | 'professionalIdemnity' | 'serviceAgreement' | 'dateCompleted'>
    & { providerdomcareSet: Array<(
      { __typename?: 'ProviderDomCare' }
      & Pick<ProviderDomCare, 'id' | 'name' | 'contractName'>
      & { cqcRegistration?: Maybe<(
        { __typename?: 'CqcRegistration' }
        & Pick<CqcRegistration, 'odsCode' | 'recordedId'>
      )> }
    )> }
  )> }
);

export type UpdateLegalEntityMutationVariables = Exact<{
  input: UpdateLegalEntityInput;
}>;


export type UpdateLegalEntityMutation = (
  { __typename?: 'Mutation' }
  & { updateLegalEntity?: Maybe<(
    { __typename?: 'UpdateLegalEntityPayload' }
    & Pick<UpdateLegalEntityPayload, 'id' | 'name' | 'preventLead' | 'representativeName' | 'representativeAddress' | 'representativeEmail' | 'representativeTelephone' | 'representativeRole' | 'registeredOfficeAddress' | 'nominatedIndividual' | 'informationGovernanceLead' | 'informationRiskOwner' | 'accountableEmergencyOfficer' | 'safeguardingLead' | 'mentalAndDolLead' | 'freedomToSpeakUpGuardian' | 'dataProtectionOfficer' | 'subcontractingDetails' | 'signatoryName' | 'caldicottGuardian' | 'abuseLead'>
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type SetOutcomeLetterStatusMutationVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
}>;


export type SetOutcomeLetterStatusMutation = (
  { __typename?: 'Mutation' }
  & { setOutcomeLetterStatus?: Maybe<(
    { __typename?: 'SetOutcomeLetterStatus' }
    & { questionnaire?: Maybe<(
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'uuid' | 'id' | 'outcomeLetterStatus'>
    )> }
  )> }
);

export type AqpSpecialismsQueryVariables = Exact<{ [key: string]: never; }>;


export type AqpSpecialismsQuery = (
  { __typename?: 'Query' }
  & { specialisms?: Maybe<Array<Maybe<(
    { __typename?: 'Specialisms' }
    & Pick<Specialisms, 'name'>
  )>>> }
);

export type AreasQueryVariables = Exact<{
  inLondon?: Maybe<Scalars['Boolean']>;
}>;


export type AreasQuery = (
  { __typename?: 'Query' }
  & { areas?: Maybe<Array<Maybe<(
    { __typename?: 'Area' }
    & Pick<Area, 'name' | 'fullName'>
    & { ccgSet: Array<(
      { __typename?: 'Ccg' }
      & Pick<Ccg, 'name'>
      & { localauthoritySet: Array<(
        { __typename?: 'LocalAuthority' }
        & Pick<LocalAuthority, 'id' | 'name'>
      )> }
    )> }
  )>>> }
);

export type ServiceLocationsQueryVariables = Exact<{
  legalEntityId: Scalars['ID'];
}>;


export type ServiceLocationsQuery = (
  { __typename?: 'Query' }
  & { serviceLocationMobilisations?: Maybe<Array<Maybe<(
    { __typename?: 'ServiceLocationMobilisation' }
    & Pick<ServiceLocationMobilisation, 'id' | 'liveInMobilisation' | 'managerFirstNameMobilisation' | 'managerLastNameMobilisation' | 'jobTitle' | 'aqpStatusMobilisation'>
    & { serviceLocation?: Maybe<(
      { __typename?: 'ServiceLocation' }
      & Pick<ServiceLocation, 'id' | 'name' | 'address'>
    )>, careTiersMobilisation: Array<(
      { __typename?: 'CareTier' }
      & Pick<CareTier, 'id' | 'name'>
    )>, areasServedMobilisation: Array<(
      { __typename?: 'Ccg' }
      & Pick<Ccg, 'id' | 'name'>
    )>, specialismsMobilisation: Array<(
      { __typename?: 'Specialisms' }
      & Pick<Specialisms, 'id' | 'name'>
    )>, languagesMobilisation: Array<(
      { __typename?: 'LanguageSelect' }
      & Pick<LanguageSelect, 'id' | 'name'>
    )>, emailsMobilisation: Array<(
      { __typename?: 'EmailModel' }
      & Pick<EmailModel, 'id' | 'address'>
    )>, phoneNumbersMobilisation: Array<(
      { __typename?: 'PhoneModel' }
      & Pick<PhoneModel, 'id' | 'phoneNumber'>
    )> }
  )>>> }
);

export type LanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type LanguagesQuery = (
  { __typename?: 'Query' }
  & { languages?: Maybe<Array<Maybe<(
    { __typename?: 'LanguageSelect' }
    & Pick<LanguageSelect, 'name' | 'code'>
  )>>> }
);

export type SubmitMobilisationFormMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type SubmitMobilisationFormMutation = (
  { __typename?: 'Mutation' }
  & { submitMobilisationForm?: Maybe<(
    { __typename?: 'SubmitMobilisationForm' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>>, entity?: Maybe<(
      { __typename?: 'LegalEntity2021' }
      & Pick<LegalEntity2021, 'uuid' | 'dateCompleted'>
    )> }
  )> }
);

export type UpdateAqpMobilisationMutationVariables = Exact<{
  input?: Maybe<UpdateServiceLocationInput>;
}>;


export type UpdateAqpMobilisationMutation = (
  { __typename?: 'Mutation' }
  & { updateAqpDataMobilisation?: Maybe<(
    { __typename?: 'UpdateServiceLocation' }
    & { serviceLocationMobilisation?: Maybe<(
      { __typename?: 'ServiceLocationMobilisations' }
      & Pick<ServiceLocationMobilisations, 'liveInMobilisation' | 'managerFirstNameMobilisation' | 'managerLastNameMobilisation' | 'jobTitle'>
      & { careTiersMobilisation: Array<(
        { __typename?: 'CareTier' }
        & Pick<CareTier, 'name'>
      )>, areasServedMobilisation: Array<(
        { __typename?: 'Ccg' }
        & Pick<Ccg, 'name'>
      )>, specialismsMobilisation: Array<(
        { __typename?: 'Specialisms' }
        & Pick<Specialisms, 'name'>
      )>, languagesMobilisation: Array<(
        { __typename?: 'LanguageSelect' }
        & Pick<LanguageSelect, 'name'>
      )>, emailsMobilisation: Array<(
        { __typename?: 'EmailModel' }
        & Pick<EmailModel, 'address'>
      )>, phoneNumbersMobilisation: Array<(
        { __typename?: 'PhoneModel' }
        & Pick<PhoneModel, 'phoneNumber'>
      )> }
    )> }
  )> }
);

export type UpdateAqpStatusMutationVariables = Exact<{
  serviceLocationId: Scalars['ID'];
  status: Scalars['Boolean'];
}>;


export type UpdateAqpStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateAqpStatusMobilisation?: Maybe<(
    { __typename?: 'UpdateServiceLocationStatus' }
    & { serviceLocationMobilisation?: Maybe<(
      { __typename?: 'ServiceLocationMobilisations' }
      & Pick<ServiceLocationMobilisations, 'aqpStatus'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type GenerateContractMutationVariables = Exact<{
  uuid: Scalars['String'];
  contractGenerated: Scalars['Boolean'];
}>;


export type GenerateContractMutation = (
  { __typename?: 'Mutation' }
  & { generateContract?: Maybe<(
    { __typename?: 'GenerateContract' }
    & { questionnaire?: Maybe<(
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'id' | 'uuid' | 'contractGenerated'>
    )> }
  )> }
);

export type IssueContractMutationVariables = Exact<{
  uuid: Scalars['String'];
  contractIssued: Scalars['Boolean'];
}>;


export type IssueContractMutation = (
  { __typename?: 'Mutation' }
  & { issueContract?: Maybe<(
    { __typename?: 'IssueContract' }
    & { questionnaire?: Maybe<(
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'id' | 'uuid' | 'contractIssued'>
    )> }
  )> }
);

export type IssueNotEmbeddedContractMutationVariables = Exact<{
  uuid: Scalars['String'];
  contractIssuedNotEmbedded: Scalars['Boolean'];
}>;


export type IssueNotEmbeddedContractMutation = (
  { __typename?: 'Mutation' }
  & { issueNotEmbeddedContract?: Maybe<(
    { __typename?: 'IssueNotEmbeddedContract' }
    & { questionnaire?: Maybe<(
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'id' | 'uuid' | 'contractIssuedNotEmbedded'>
    )> }
  )> }
);

export type SignContractMutationVariables = Exact<{
  uuid: Scalars['String'];
  contractSigned: Scalars['Boolean'];
}>;


export type SignContractMutation = (
  { __typename?: 'Mutation' }
  & { signContract?: Maybe<(
    { __typename?: 'SignContract' }
    & { questionnaire?: Maybe<(
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'id' | 'uuid' | 'contractSigned'>
    )> }
  )> }
);

export type UpdateSignatureRequestMutationVariables = Exact<{
  uuid: Scalars['String'];
  signatureId: Scalars['String'];
  signatureRequestId: Scalars['String'];
}>;


export type UpdateSignatureRequestMutation = (
  { __typename?: 'Mutation' }
  & { updateSignatureRequest?: Maybe<(
    { __typename?: 'UpdateSignatureRequest' }
    & { questionnaire?: Maybe<(
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'id' | 'uuid' | 'signatureId' | 'signatureRequestId'>
    )> }
  )> }
);

export type SignContractsMutationVariables = Exact<{
  signatureRequestIds: Array<Maybe<Scalars['String']>>;
}>;


export type SignContractsMutation = (
  { __typename?: 'Mutation' }
  & { signContracts?: Maybe<(
    { __typename?: 'SignContracts' }
    & { questionnaires?: Maybe<Array<Maybe<(
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'id' | 'uuid' | 'contractSigned'>
    )>>> }
  )> }
);

export type IssueLettersMutationVariables = Exact<{ [key: string]: never; }>;


export type IssueLettersMutation = (
  { __typename?: 'Mutation' }
  & { issueLetters?: Maybe<(
    { __typename?: 'IssueLetters' }
    & Pick<IssueLetters, 'issued'>
  )> }
);

export type LettersIssuedQueryVariables = Exact<{ [key: string]: never; }>;


export type LettersIssuedQuery = (
  { __typename?: 'Query' }
  & { lettersIssuedInfo?: Maybe<(
    { __typename?: 'LettersIssuedInfo' }
    & Pick<LettersIssuedInfo, 'lettersIssued' | 'lettersIssuedDate'>
  )> }
);

export type CommissionerContactInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type CommissionerContactInformationQuery = (
  { __typename?: 'Query' }
  & { commissionersContactInformation?: Maybe<Array<Maybe<(
    { __typename?: 'CommissionerContactInformation' }
    & Pick<CommissionerContactInformation, 'signerNumber' | 'name' | 'emailAddress'>
  )>>> }
);


export const ViewerDocument = gql`
    query Viewer {
  viewer {
    firstName
    lastName
    username
    email
    isActive
    lph
    userProfile {
      isEvaluator
      provideruserprofile {
        domcareProcurement {
          applicationName
          qualificationquestionnaire {
            id
            uuid
            letterIssued
            outcomeLetterStatus
            contractIssued
            contractSigned
            signatureId
          }
          legalEntity2021 {
            uuid
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(baseOptions?: Apollo.QueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
        return Apollo.useQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, baseOptions);
      }
export function useViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
          return Apollo.useLazyQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, baseOptions);
        }
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = Apollo.QueryResult<ViewerQuery, ViewerQueryVariables>;
export const PasswordChangeDocument = gql`
    mutation PasswordChange($input: PasswordChangeInput!) {
  passwordChange(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type PasswordChangeMutationFn = Apollo.MutationFunction<PasswordChangeMutation, PasswordChangeMutationVariables>;

/**
 * __usePasswordChangeMutation__
 *
 * To run a mutation, you first call `usePasswordChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordChangeMutation, { data, loading, error }] = usePasswordChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePasswordChangeMutation(baseOptions?: Apollo.MutationHookOptions<PasswordChangeMutation, PasswordChangeMutationVariables>) {
        return Apollo.useMutation<PasswordChangeMutation, PasswordChangeMutationVariables>(PasswordChangeDocument, baseOptions);
      }
export type PasswordChangeMutationHookResult = ReturnType<typeof usePasswordChangeMutation>;
export type PasswordChangeMutationResult = Apollo.MutationResult<PasswordChangeMutation>;
export type PasswordChangeMutationOptions = Apollo.BaseMutationOptions<PasswordChangeMutation, PasswordChangeMutationVariables>;
export const UpdateDetailsDocument = gql`
    mutation UpdateDetails($input: UpdateDetailsInput!) {
  updateDetails(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type UpdateDetailsMutationFn = Apollo.MutationFunction<UpdateDetailsMutation, UpdateDetailsMutationVariables>;

/**
 * __useUpdateDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDetailsMutation, { data, loading, error }] = useUpdateDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDetailsMutation, UpdateDetailsMutationVariables>) {
        return Apollo.useMutation<UpdateDetailsMutation, UpdateDetailsMutationVariables>(UpdateDetailsDocument, baseOptions);
      }
export type UpdateDetailsMutationHookResult = ReturnType<typeof useUpdateDetailsMutation>;
export type UpdateDetailsMutationResult = Apollo.MutationResult<UpdateDetailsMutation>;
export type UpdateDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateDetailsMutation, UpdateDetailsMutationVariables>;
export const ChangeEmailDocument = gql`
    mutation ChangeEmail($input: ChangeEmailInput!) {
  changeEmail(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type ChangeEmailMutationFn = Apollo.MutationFunction<ChangeEmailMutation, ChangeEmailMutationVariables>;

/**
 * __useChangeEmailMutation__
 *
 * To run a mutation, you first call `useChangeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailMutation, { data, loading, error }] = useChangeEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeEmailMutation(baseOptions?: Apollo.MutationHookOptions<ChangeEmailMutation, ChangeEmailMutationVariables>) {
        return Apollo.useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(ChangeEmailDocument, baseOptions);
      }
export type ChangeEmailMutationHookResult = ReturnType<typeof useChangeEmailMutation>;
export type ChangeEmailMutationResult = Apollo.MutationResult<ChangeEmailMutation>;
export type ChangeEmailMutationOptions = Apollo.BaseMutationOptions<ChangeEmailMutation, ChangeEmailMutationVariables>;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    token
    errors {
      field
      messages
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    success
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ActivateUserDocument = gql`
    mutation ActivateUser($activationKey: String!) {
  activate(activationKey: $activationKey) {
    errors {
      field
      messages
    }
  }
}
    `;
export type ActivateUserMutationFn = Apollo.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      activationKey: // value for 'activationKey'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: Apollo.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return Apollo.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = Apollo.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = Apollo.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const ResendActivationDocument = gql`
    mutation ResendActivation($email: String!) {
  resend(email: $email) {
    errors {
      field
      messages
    }
  }
}
    `;
export type ResendActivationMutationFn = Apollo.MutationFunction<ResendActivationMutation, ResendActivationMutationVariables>;

/**
 * __useResendActivationMutation__
 *
 * To run a mutation, you first call `useResendActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendActivationMutation, { data, loading, error }] = useResendActivationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendActivationMutation(baseOptions?: Apollo.MutationHookOptions<ResendActivationMutation, ResendActivationMutationVariables>) {
        return Apollo.useMutation<ResendActivationMutation, ResendActivationMutationVariables>(ResendActivationDocument, baseOptions);
      }
export type ResendActivationMutationHookResult = ReturnType<typeof useResendActivationMutation>;
export type ResendActivationMutationResult = Apollo.MutationResult<ResendActivationMutation>;
export type ResendActivationMutationOptions = Apollo.BaseMutationOptions<ResendActivationMutation, ResendActivationMutationVariables>;
export const PasswordResetDocument = gql`
    mutation PasswordReset($email: String!) {
  passwordReset(email: $email) {
    resetLink
    errors {
      field
      messages
    }
  }
}
    `;
export type PasswordResetMutationFn = Apollo.MutationFunction<PasswordResetMutation, PasswordResetMutationVariables>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetMutation, PasswordResetMutationVariables>) {
        return Apollo.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(PasswordResetDocument, baseOptions);
      }
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<PasswordResetMutation, PasswordResetMutationVariables>;
export const ResetPasswordConfirmDocument = gql`
    mutation ResetPasswordConfirm($uid: String!, $token: String!, $newPassword: String!, $reNewPassword: String!) {
  resetPasswordConfirm(
    uid: $uid
    token: $token
    newPassword: $newPassword
    reNewPassword: $reNewPassword
  ) {
    errors {
      field
      messages
    }
  }
}
    `;
export type ResetPasswordConfirmMutationFn = Apollo.MutationFunction<ResetPasswordConfirmMutation, ResetPasswordConfirmMutationVariables>;

/**
 * __useResetPasswordConfirmMutation__
 *
 * To run a mutation, you first call `useResetPasswordConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordConfirmMutation, { data, loading, error }] = useResetPasswordConfirmMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *      reNewPassword: // value for 'reNewPassword'
 *   },
 * });
 */
export function useResetPasswordConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordConfirmMutation, ResetPasswordConfirmMutationVariables>) {
        return Apollo.useMutation<ResetPasswordConfirmMutation, ResetPasswordConfirmMutationVariables>(ResetPasswordConfirmDocument, baseOptions);
      }
export type ResetPasswordConfirmMutationHookResult = ReturnType<typeof useResetPasswordConfirmMutation>;
export type ResetPasswordConfirmMutationResult = Apollo.MutationResult<ResetPasswordConfirmMutation>;
export type ResetPasswordConfirmMutationOptions = Apollo.BaseMutationOptions<ResetPasswordConfirmMutation, ResetPasswordConfirmMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    errors {
      field
      messages
    }
    activationKey
    token
    registration {
      firstName
      lastName
      applicationName
      email
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const SectionDocument = gql`
    query Section($uuid: String!, $order: Float!) {
  section(uuid: $uuid, order: $order) {
    id
    order
    title
    categories {
      id
      order
      title
      details
      questions {
        id
        order
        type
        title
        choices
        details
        mandatory
        notApplicable
        commentBox
        charLimit
        answers {
          id
          value
          evalStatus
          values
          comment
          outcome
          rationale
          commissioningOutcome
          clinicalOutcome
          commissioningRationale
          clinicalRationale
          moderationComment
          moderationOutcome
          clarificationQuestions {
            id
            body
            answerText
            datePublished
            dateSubmitted
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSectionQuery__
 *
 * To run a query within a React component, call `useSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSectionQuery(baseOptions?: Apollo.QueryHookOptions<SectionQuery, SectionQueryVariables>) {
        return Apollo.useQuery<SectionQuery, SectionQueryVariables>(SectionDocument, baseOptions);
      }
export function useSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SectionQuery, SectionQueryVariables>) {
          return Apollo.useLazyQuery<SectionQuery, SectionQueryVariables>(SectionDocument, baseOptions);
        }
export type SectionQueryHookResult = ReturnType<typeof useSectionQuery>;
export type SectionLazyQueryHookResult = ReturnType<typeof useSectionLazyQuery>;
export type SectionQueryResult = Apollo.QueryResult<SectionQuery, SectionQueryVariables>;
export const CreateAnswerDocument = gql`
    mutation CreateAnswer($input: CreateAnswerInput!) {
  createAnswer(input: $input) {
    answer {
      id
      value
      values
      comment
    }
  }
}
    `;
export type CreateAnswerMutationFn = Apollo.MutationFunction<CreateAnswerMutation, CreateAnswerMutationVariables>;

/**
 * __useCreateAnswerMutation__
 *
 * To run a mutation, you first call `useCreateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnswerMutation, { data, loading, error }] = useCreateAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAnswerMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnswerMutation, CreateAnswerMutationVariables>) {
        return Apollo.useMutation<CreateAnswerMutation, CreateAnswerMutationVariables>(CreateAnswerDocument, baseOptions);
      }
export type CreateAnswerMutationHookResult = ReturnType<typeof useCreateAnswerMutation>;
export type CreateAnswerMutationResult = Apollo.MutationResult<CreateAnswerMutation>;
export type CreateAnswerMutationOptions = Apollo.BaseMutationOptions<CreateAnswerMutation, CreateAnswerMutationVariables>;
export const EvaluatorTeamDocument = gql`
    query EvaluatorTeam($username: String) {
  team(username: $username) {
    applications {
      id
      applicationName
      qualificationquestionnaire {
        uuid
        evaluatorStatus
        technicalEvaluatorProgress
        technicalClarificationProgress
      }
    }
  }
}
    `;

/**
 * __useEvaluatorTeamQuery__
 *
 * To run a query within a React component, call `useEvaluatorTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvaluatorTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvaluatorTeamQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useEvaluatorTeamQuery(baseOptions?: Apollo.QueryHookOptions<EvaluatorTeamQuery, EvaluatorTeamQueryVariables>) {
        return Apollo.useQuery<EvaluatorTeamQuery, EvaluatorTeamQueryVariables>(EvaluatorTeamDocument, baseOptions);
      }
export function useEvaluatorTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EvaluatorTeamQuery, EvaluatorTeamQueryVariables>) {
          return Apollo.useLazyQuery<EvaluatorTeamQuery, EvaluatorTeamQueryVariables>(EvaluatorTeamDocument, baseOptions);
        }
export type EvaluatorTeamQueryHookResult = ReturnType<typeof useEvaluatorTeamQuery>;
export type EvaluatorTeamLazyQueryHookResult = ReturnType<typeof useEvaluatorTeamLazyQuery>;
export type EvaluatorTeamQueryResult = Apollo.QueryResult<EvaluatorTeamQuery, EvaluatorTeamQueryVariables>;
export const CreateTechnicalEvaluationQuestionDocument = gql`
    mutation CreateTechnicalEvaluationQuestion($input: TechnicalEvaluatorListInput!) {
  createTechnicalEvaluationQuestions(input: $input) {
    answers {
      id
      value
      outcome
      rationale
    }
  }
}
    `;
export type CreateTechnicalEvaluationQuestionMutationFn = Apollo.MutationFunction<CreateTechnicalEvaluationQuestionMutation, CreateTechnicalEvaluationQuestionMutationVariables>;

/**
 * __useCreateTechnicalEvaluationQuestionMutation__
 *
 * To run a mutation, you first call `useCreateTechnicalEvaluationQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTechnicalEvaluationQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTechnicalEvaluationQuestionMutation, { data, loading, error }] = useCreateTechnicalEvaluationQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTechnicalEvaluationQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateTechnicalEvaluationQuestionMutation, CreateTechnicalEvaluationQuestionMutationVariables>) {
        return Apollo.useMutation<CreateTechnicalEvaluationQuestionMutation, CreateTechnicalEvaluationQuestionMutationVariables>(CreateTechnicalEvaluationQuestionDocument, baseOptions);
      }
export type CreateTechnicalEvaluationQuestionMutationHookResult = ReturnType<typeof useCreateTechnicalEvaluationQuestionMutation>;
export type CreateTechnicalEvaluationQuestionMutationResult = Apollo.MutationResult<CreateTechnicalEvaluationQuestionMutation>;
export type CreateTechnicalEvaluationQuestionMutationOptions = Apollo.BaseMutationOptions<CreateTechnicalEvaluationQuestionMutation, CreateTechnicalEvaluationQuestionMutationVariables>;
export const SectionListDocument = gql`
    query SectionList($uuid: String!) {
  questionnaire(uuid: $uuid) {
    id
    uuid
    lastSave
    dateCompleted
    clarificationQuestionsDeadline
    clarificationQuestionsSubmittedDate
    clarificationQuestionsPending
    readOnly
    sections {
      id
      order
      title
      status
      cqStatus
      cqDescription
      complianceStatus
      description
    }
    user {
      id
      applicationName
      evaluatorTeam {
        name
      }
    }
    technicalEvaluatorProgress
  }
}
    `;

/**
 * __useSectionListQuery__
 *
 * To run a query within a React component, call `useSectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionListQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSectionListQuery(baseOptions?: Apollo.QueryHookOptions<SectionListQuery, SectionListQueryVariables>) {
        return Apollo.useQuery<SectionListQuery, SectionListQueryVariables>(SectionListDocument, baseOptions);
      }
export function useSectionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SectionListQuery, SectionListQueryVariables>) {
          return Apollo.useLazyQuery<SectionListQuery, SectionListQueryVariables>(SectionListDocument, baseOptions);
        }
export type SectionListQueryHookResult = ReturnType<typeof useSectionListQuery>;
export type SectionListLazyQueryHookResult = ReturnType<typeof useSectionListLazyQuery>;
export type SectionListQueryResult = Apollo.QueryResult<SectionListQuery, SectionListQueryVariables>;
export const SectionsExportDocument = gql`
    query SectionsExport($uuid: String!) {
  sectionsExport(uuid: $uuid) {
    id
    uuid
    lastSave
    dateCompleted
    readOnly
    sections {
      id
      order
      title
      status
      description
      categories {
        id
        order
        title
        details
        questions {
          id
          order
          type
          title
          choices
          details
          mandatory
          notApplicable
          commentBox
          charLimit
          answers {
            value
            values
            comment
            clarificationQuestions {
              id
              body
              answerText
              datePublished
              dateSubmitted
            }
          }
        }
      }
    }
    user {
      id
      applicationName
      provideruserprofile {
        user {
          fullName
          email
        }
      }
    }
  }
}
    `;

/**
 * __useSectionsExportQuery__
 *
 * To run a query within a React component, call `useSectionsExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionsExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionsExportQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSectionsExportQuery(baseOptions?: Apollo.QueryHookOptions<SectionsExportQuery, SectionsExportQueryVariables>) {
        return Apollo.useQuery<SectionsExportQuery, SectionsExportQueryVariables>(SectionsExportDocument, baseOptions);
      }
export function useSectionsExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SectionsExportQuery, SectionsExportQueryVariables>) {
          return Apollo.useLazyQuery<SectionsExportQuery, SectionsExportQueryVariables>(SectionsExportDocument, baseOptions);
        }
export type SectionsExportQueryHookResult = ReturnType<typeof useSectionsExportQuery>;
export type SectionsExportLazyQueryHookResult = ReturnType<typeof useSectionsExportLazyQuery>;
export type SectionsExportQueryResult = Apollo.QueryResult<SectionsExportQuery, SectionsExportQueryVariables>;
export const CreateAnswersDocument = gql`
    mutation CreateAnswers($input: CreateAnswersInput!) {
  createAnswers(input: $input) {
    answers {
      id
      value
      values
      comment
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type CreateAnswersMutationFn = Apollo.MutationFunction<CreateAnswersMutation, CreateAnswersMutationVariables>;

/**
 * __useCreateAnswersMutation__
 *
 * To run a mutation, you first call `useCreateAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnswersMutation, { data, loading, error }] = useCreateAnswersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAnswersMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnswersMutation, CreateAnswersMutationVariables>) {
        return Apollo.useMutation<CreateAnswersMutation, CreateAnswersMutationVariables>(CreateAnswersDocument, baseOptions);
      }
export type CreateAnswersMutationHookResult = ReturnType<typeof useCreateAnswersMutation>;
export type CreateAnswersMutationResult = Apollo.MutationResult<CreateAnswersMutation>;
export type CreateAnswersMutationOptions = Apollo.BaseMutationOptions<CreateAnswersMutation, CreateAnswersMutationVariables>;
export const SubmitApplicationDocument = gql`
    mutation SubmitApplication($input: SubmitApplicationInput!) {
  submitApplication(input: $input) {
    questionnaire {
      id
      dateCompleted
    }
  }
}
    `;
export type SubmitApplicationMutationFn = Apollo.MutationFunction<SubmitApplicationMutation, SubmitApplicationMutationVariables>;

/**
 * __useSubmitApplicationMutation__
 *
 * To run a mutation, you first call `useSubmitApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitApplicationMutation, { data, loading, error }] = useSubmitApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitApplicationMutation(baseOptions?: Apollo.MutationHookOptions<SubmitApplicationMutation, SubmitApplicationMutationVariables>) {
        return Apollo.useMutation<SubmitApplicationMutation, SubmitApplicationMutationVariables>(SubmitApplicationDocument, baseOptions);
      }
export type SubmitApplicationMutationHookResult = ReturnType<typeof useSubmitApplicationMutation>;
export type SubmitApplicationMutationResult = Apollo.MutationResult<SubmitApplicationMutation>;
export type SubmitApplicationMutationOptions = Apollo.BaseMutationOptions<SubmitApplicationMutation, SubmitApplicationMutationVariables>;
export const DocumentsDocument = gql`
    query Documents {
  documents {
    id
    name
    description
    url
    created
  }
}
    `;

/**
 * __useDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
        return Apollo.useQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, baseOptions);
      }
export function useDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
          return Apollo.useLazyQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, baseOptions);
        }
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>;
export type DocumentsLazyQueryHookResult = ReturnType<typeof useDocumentsLazyQuery>;
export type DocumentsQueryResult = Apollo.QueryResult<DocumentsQuery, DocumentsQueryVariables>;
export const DocumentDocument = gql`
    mutation Document($input: DocumentMutationInput!) {
  documentMutation(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DocumentMutationFn = Apollo.MutationFunction<DocumentMutation, DocumentMutationVariables>;

/**
 * __useDocumentMutation__
 *
 * To run a mutation, you first call `useDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentMutation, { data, loading, error }] = useDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DocumentMutation, DocumentMutationVariables>) {
        return Apollo.useMutation<DocumentMutation, DocumentMutationVariables>(DocumentDocument, baseOptions);
      }
export type DocumentMutationHookResult = ReturnType<typeof useDocumentMutation>;
export type DocumentMutationResult = Apollo.MutationResult<DocumentMutation>;
export type DocumentMutationOptions = Apollo.BaseMutationOptions<DocumentMutation, DocumentMutationVariables>;
export const DeleteDocumentDocument = gql`
    mutation DeleteDocument($id: ID!) {
  deleteDocument(id: $id) {
    deleted
  }
}
    `;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
        return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, baseOptions);
      }
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const UnreadMessagesDocument = gql`
    query UnreadMessages {
  unreadMessagesCount
}
    `;

/**
 * __useUnreadMessagesQuery__
 *
 * To run a query within a React component, call `useUnreadMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnreadMessagesQuery(baseOptions?: Apollo.QueryHookOptions<UnreadMessagesQuery, UnreadMessagesQueryVariables>) {
        return Apollo.useQuery<UnreadMessagesQuery, UnreadMessagesQueryVariables>(UnreadMessagesDocument, baseOptions);
      }
export function useUnreadMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnreadMessagesQuery, UnreadMessagesQueryVariables>) {
          return Apollo.useLazyQuery<UnreadMessagesQuery, UnreadMessagesQueryVariables>(UnreadMessagesDocument, baseOptions);
        }
export type UnreadMessagesQueryHookResult = ReturnType<typeof useUnreadMessagesQuery>;
export type UnreadMessagesLazyQueryHookResult = ReturnType<typeof useUnreadMessagesLazyQuery>;
export type UnreadMessagesQueryResult = Apollo.QueryResult<UnreadMessagesQuery, UnreadMessagesQueryVariables>;
export const ThreadsDocument = gql`
    query Threads($read: Boolean, $audience: String, $first: Int, $after: String, $orderBy: String) {
  threads(
    read: $read
    audience: $audience
    first: $first
    after: $after
    orderBy: $orderBy
  ) {
    pageInfo {
      hasNextPage
    }
    totalCount
    edges {
      cursor
      node {
        id
        sendTo {
          id
          provideruserprofile {
            user {
              id
              firstName
            }
          }
        }
        messages {
          id
          subject
          read
          sender {
            id
            provideruserprofile {
              user {
                id
                fullName
              }
            }
          }
          attachment {
            id
            name
            url
          }
          created
          audience
          body
        }
      }
    }
  }
}
    `;

/**
 * __useThreadsQuery__
 *
 * To run a query within a React component, call `useThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadsQuery({
 *   variables: {
 *      read: // value for 'read'
 *      audience: // value for 'audience'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useThreadsQuery(baseOptions?: Apollo.QueryHookOptions<ThreadsQuery, ThreadsQueryVariables>) {
        return Apollo.useQuery<ThreadsQuery, ThreadsQueryVariables>(ThreadsDocument, baseOptions);
      }
export function useThreadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThreadsQuery, ThreadsQueryVariables>) {
          return Apollo.useLazyQuery<ThreadsQuery, ThreadsQueryVariables>(ThreadsDocument, baseOptions);
        }
export type ThreadsQueryHookResult = ReturnType<typeof useThreadsQuery>;
export type ThreadsLazyQueryHookResult = ReturnType<typeof useThreadsLazyQuery>;
export type ThreadsQueryResult = Apollo.QueryResult<ThreadsQuery, ThreadsQueryVariables>;
export const CreateMessageDocument = gql`
    mutation CreateMessage($input: MessageMutationInput!) {
  messageMutation(input: $input) {
    message {
      subject
      body
    }
  }
}
    `;
export type CreateMessageMutationFn = Apollo.MutationFunction<CreateMessageMutation, CreateMessageMutationVariables>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>) {
        return Apollo.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(CreateMessageDocument, baseOptions);
      }
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = Apollo.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<CreateMessageMutation, CreateMessageMutationVariables>;
export const ProcurementUserProfilesDocument = gql`
    query ProcurementUserProfiles($complete: Boolean) {
  procurementUserProfiles(complete: $complete) {
    id
    applicationName
    evaluatorTeam {
      id
    }
  }
}
    `;

/**
 * __useProcurementUserProfilesQuery__
 *
 * To run a query within a React component, call `useProcurementUserProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcurementUserProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcurementUserProfilesQuery({
 *   variables: {
 *      complete: // value for 'complete'
 *   },
 * });
 */
export function useProcurementUserProfilesQuery(baseOptions?: Apollo.QueryHookOptions<ProcurementUserProfilesQuery, ProcurementUserProfilesQueryVariables>) {
        return Apollo.useQuery<ProcurementUserProfilesQuery, ProcurementUserProfilesQueryVariables>(ProcurementUserProfilesDocument, baseOptions);
      }
export function useProcurementUserProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcurementUserProfilesQuery, ProcurementUserProfilesQueryVariables>) {
          return Apollo.useLazyQuery<ProcurementUserProfilesQuery, ProcurementUserProfilesQueryVariables>(ProcurementUserProfilesDocument, baseOptions);
        }
export type ProcurementUserProfilesQueryHookResult = ReturnType<typeof useProcurementUserProfilesQuery>;
export type ProcurementUserProfilesLazyQueryHookResult = ReturnType<typeof useProcurementUserProfilesLazyQuery>;
export type ProcurementUserProfilesQueryResult = Apollo.QueryResult<ProcurementUserProfilesQuery, ProcurementUserProfilesQueryVariables>;
export const ReadMessageDocument = gql`
    mutation ReadMessage($id: ID!) {
  readMessage(id: $id) {
    thread {
      id
      sendTo {
        id
        provideruserprofile {
          user {
            id
            firstName
          }
        }
      }
      messages {
        id
        subject
        read
        sender {
          id
          provideruserprofile {
            user {
              id
              fullName
            }
          }
        }
        attachment {
          id
          name
          url
        }
        created
        audience
        body
      }
    }
  }
}
    `;
export type ReadMessageMutationFn = Apollo.MutationFunction<ReadMessageMutation, ReadMessageMutationVariables>;

/**
 * __useReadMessageMutation__
 *
 * To run a mutation, you first call `useReadMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readMessageMutation, { data, loading, error }] = useReadMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadMessageMutation(baseOptions?: Apollo.MutationHookOptions<ReadMessageMutation, ReadMessageMutationVariables>) {
        return Apollo.useMutation<ReadMessageMutation, ReadMessageMutationVariables>(ReadMessageDocument, baseOptions);
      }
export type ReadMessageMutationHookResult = ReturnType<typeof useReadMessageMutation>;
export type ReadMessageMutationResult = Apollo.MutationResult<ReadMessageMutation>;
export type ReadMessageMutationOptions = Apollo.BaseMutationOptions<ReadMessageMutation, ReadMessageMutationVariables>;
export const QuestionnairesDocument = gql`
    query Questionnaires($first: Int!, $after: String) {
  questionnaires(first: $first, after: $after) {
    totalCount
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        uuid
        created
        status
        ccStatus
        feStatus
        user {
          applicationName
          provideruserprofile {
            user {
              id
              fullName
              email
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useQuestionnairesQuery__
 *
 * To run a query within a React component, call `useQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnairesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useQuestionnairesQuery(baseOptions?: Apollo.QueryHookOptions<QuestionnairesQuery, QuestionnairesQueryVariables>) {
        return Apollo.useQuery<QuestionnairesQuery, QuestionnairesQueryVariables>(QuestionnairesDocument, baseOptions);
      }
export function useQuestionnairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnairesQuery, QuestionnairesQueryVariables>) {
          return Apollo.useLazyQuery<QuestionnairesQuery, QuestionnairesQueryVariables>(QuestionnairesDocument, baseOptions);
        }
export type QuestionnairesQueryHookResult = ReturnType<typeof useQuestionnairesQuery>;
export type QuestionnairesLazyQueryHookResult = ReturnType<typeof useQuestionnairesLazyQuery>;
export type QuestionnairesQueryResult = Apollo.QueryResult<QuestionnairesQuery, QuestionnairesQueryVariables>;
export const QuestionnairesExportDocument = gql`
    query QuestionnairesExport($outcome: String!) {
  questionnairesExport(outcome: $outcome) {
    id
    uuid
  }
}
    `;

/**
 * __useQuestionnairesExportQuery__
 *
 * To run a query within a React component, call `useQuestionnairesExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnairesExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnairesExportQuery({
 *   variables: {
 *      outcome: // value for 'outcome'
 *   },
 * });
 */
export function useQuestionnairesExportQuery(baseOptions?: Apollo.QueryHookOptions<QuestionnairesExportQuery, QuestionnairesExportQueryVariables>) {
        return Apollo.useQuery<QuestionnairesExportQuery, QuestionnairesExportQueryVariables>(QuestionnairesExportDocument, baseOptions);
      }
export function useQuestionnairesExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnairesExportQuery, QuestionnairesExportQueryVariables>) {
          return Apollo.useLazyQuery<QuestionnairesExportQuery, QuestionnairesExportQueryVariables>(QuestionnairesExportDocument, baseOptions);
        }
export type QuestionnairesExportQueryHookResult = ReturnType<typeof useQuestionnairesExportQuery>;
export type QuestionnairesExportLazyQueryHookResult = ReturnType<typeof useQuestionnairesExportLazyQuery>;
export type QuestionnairesExportQueryResult = Apollo.QueryResult<QuestionnairesExportQuery, QuestionnairesExportQueryVariables>;
export const EvaluationTableDocument = gql`
    query EvaluationTable($first: Int!, $after: String, $submitted: Boolean, $name: String, $ccStatus: String, $teStatus: String, $feStatus: String, $overallStatus: String) {
  questionnaires(
    first: $first
    after: $after
    submitted: $submitted
    name: $name
    ccStatus: $ccStatus
    teStatus: $teStatus
    feStatus: $feStatus
    overallStatus: $overallStatus
  ) {
    totalCount
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        uuid
        ccStatus
        feStatus
        teStatus
        overallStatus
        outcomeLetterStatus
        user {
          applicationName
        }
      }
    }
  }
}
    `;

/**
 * __useEvaluationTableQuery__
 *
 * To run a query within a React component, call `useEvaluationTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvaluationTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvaluationTableQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      submitted: // value for 'submitted'
 *      name: // value for 'name'
 *      ccStatus: // value for 'ccStatus'
 *      teStatus: // value for 'teStatus'
 *      feStatus: // value for 'feStatus'
 *      overallStatus: // value for 'overallStatus'
 *   },
 * });
 */
export function useEvaluationTableQuery(baseOptions?: Apollo.QueryHookOptions<EvaluationTableQuery, EvaluationTableQueryVariables>) {
        return Apollo.useQuery<EvaluationTableQuery, EvaluationTableQueryVariables>(EvaluationTableDocument, baseOptions);
      }
export function useEvaluationTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EvaluationTableQuery, EvaluationTableQueryVariables>) {
          return Apollo.useLazyQuery<EvaluationTableQuery, EvaluationTableQueryVariables>(EvaluationTableDocument, baseOptions);
        }
export type EvaluationTableQueryHookResult = ReturnType<typeof useEvaluationTableQuery>;
export type EvaluationTableLazyQueryHookResult = ReturnType<typeof useEvaluationTableLazyQuery>;
export type EvaluationTableQueryResult = Apollo.QueryResult<EvaluationTableQuery, EvaluationTableQueryVariables>;
export const MobilisationTableDocument = gql`
    query MobilisationTable($first: Int!, $after: String, $submitted: Boolean, $name: String, $ccStatus: String, $teStatus: String, $feStatus: String, $overallStatus: String, $outcome: String, $mobilisationStatus: String, $contractSigned: String) {
  questionnaires(
    first: $first
    after: $after
    submitted: $submitted
    name: $name
    ccStatus: $ccStatus
    teStatus: $teStatus
    feStatus: $feStatus
    overallStatus: $overallStatus
    outcome: $outcome
    mobilisationStatus: $mobilisationStatus
    contractSigned: $contractSigned
  ) {
    totalCount
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        uuid
        contractGenerated
        contractIssued
        contractIssuedNotEmbedded
        contractSigned
        signatureRequestId
        mobilisationStatus
        outcomeLetterStatus
        overallStatus
        user {
          legalEntity2021 {
            uuid
            name
            representativeName
            representativeEmail
            signatoryName
          }
          provideruserprofile {
            user {
              id
              fullName
              email
            }
          }
          applicationName
        }
      }
    }
  }
}
    `;

/**
 * __useMobilisationTableQuery__
 *
 * To run a query within a React component, call `useMobilisationTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useMobilisationTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMobilisationTableQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      submitted: // value for 'submitted'
 *      name: // value for 'name'
 *      ccStatus: // value for 'ccStatus'
 *      teStatus: // value for 'teStatus'
 *      feStatus: // value for 'feStatus'
 *      overallStatus: // value for 'overallStatus'
 *      outcome: // value for 'outcome'
 *      mobilisationStatus: // value for 'mobilisationStatus'
 *      contractSigned: // value for 'contractSigned'
 *   },
 * });
 */
export function useMobilisationTableQuery(baseOptions?: Apollo.QueryHookOptions<MobilisationTableQuery, MobilisationTableQueryVariables>) {
        return Apollo.useQuery<MobilisationTableQuery, MobilisationTableQueryVariables>(MobilisationTableDocument, baseOptions);
      }
export function useMobilisationTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MobilisationTableQuery, MobilisationTableQueryVariables>) {
          return Apollo.useLazyQuery<MobilisationTableQuery, MobilisationTableQueryVariables>(MobilisationTableDocument, baseOptions);
        }
export type MobilisationTableQueryHookResult = ReturnType<typeof useMobilisationTableQuery>;
export type MobilisationTableLazyQueryHookResult = ReturnType<typeof useMobilisationTableLazyQuery>;
export type MobilisationTableQueryResult = Apollo.QueryResult<MobilisationTableQuery, MobilisationTableQueryVariables>;
export const EvaluationStatisticsDocument = gql`
    query EvaluationStatistics {
  evaluationStatistics {
    title
    value
  }
}
    `;

/**
 * __useEvaluationStatisticsQuery__
 *
 * To run a query within a React component, call `useEvaluationStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvaluationStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvaluationStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEvaluationStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<EvaluationStatisticsQuery, EvaluationStatisticsQueryVariables>) {
        return Apollo.useQuery<EvaluationStatisticsQuery, EvaluationStatisticsQueryVariables>(EvaluationStatisticsDocument, baseOptions);
      }
export function useEvaluationStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EvaluationStatisticsQuery, EvaluationStatisticsQueryVariables>) {
          return Apollo.useLazyQuery<EvaluationStatisticsQuery, EvaluationStatisticsQueryVariables>(EvaluationStatisticsDocument, baseOptions);
        }
export type EvaluationStatisticsQueryHookResult = ReturnType<typeof useEvaluationStatisticsQuery>;
export type EvaluationStatisticsLazyQueryHookResult = ReturnType<typeof useEvaluationStatisticsLazyQuery>;
export type EvaluationStatisticsQueryResult = Apollo.QueryResult<EvaluationStatisticsQuery, EvaluationStatisticsQueryVariables>;
export const QuestionnaireStatisticsDocument = gql`
    query QuestionnaireStatistics {
  questionnaireStatistics {
    title
    value
  }
}
    `;

/**
 * __useQuestionnaireStatisticsQuery__
 *
 * To run a query within a React component, call `useQuestionnaireStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuestionnaireStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<QuestionnaireStatisticsQuery, QuestionnaireStatisticsQueryVariables>) {
        return Apollo.useQuery<QuestionnaireStatisticsQuery, QuestionnaireStatisticsQueryVariables>(QuestionnaireStatisticsDocument, baseOptions);
      }
export function useQuestionnaireStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireStatisticsQuery, QuestionnaireStatisticsQueryVariables>) {
          return Apollo.useLazyQuery<QuestionnaireStatisticsQuery, QuestionnaireStatisticsQueryVariables>(QuestionnaireStatisticsDocument, baseOptions);
        }
export type QuestionnaireStatisticsQueryHookResult = ReturnType<typeof useQuestionnaireStatisticsQuery>;
export type QuestionnaireStatisticsLazyQueryHookResult = ReturnType<typeof useQuestionnaireStatisticsLazyQuery>;
export type QuestionnaireStatisticsQueryResult = Apollo.QueryResult<QuestionnaireStatisticsQuery, QuestionnaireStatisticsQueryVariables>;
export const TeamDocument = gql`
    query Team {
  teams {
    id
    name
    applications {
      id
      applicationName
      qualificationquestionnaire {
        uuid
        clinicalStatus
        commissioningStatus
      }
    }
    evaluatorSet {
      evaluatorType
      evaluator {
        id
      }
    }
  }
}
    `;

/**
 * __useTeamQuery__
 *
 * To run a query within a React component, call `useTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamQuery(baseOptions?: Apollo.QueryHookOptions<TeamQuery, TeamQueryVariables>) {
        return Apollo.useQuery<TeamQuery, TeamQueryVariables>(TeamDocument, baseOptions);
      }
export function useTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamQuery, TeamQueryVariables>) {
          return Apollo.useLazyQuery<TeamQuery, TeamQueryVariables>(TeamDocument, baseOptions);
        }
export type TeamQueryHookResult = ReturnType<typeof useTeamQuery>;
export type TeamLazyQueryHookResult = ReturnType<typeof useTeamLazyQuery>;
export type TeamQueryResult = Apollo.QueryResult<TeamQuery, TeamQueryVariables>;
export const EvaluatorsDocument = gql`
    query Evaluators {
  userProfiles {
    id
    user {
      id
      username
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useEvaluatorsQuery__
 *
 * To run a query within a React component, call `useEvaluatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvaluatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvaluatorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEvaluatorsQuery(baseOptions?: Apollo.QueryHookOptions<EvaluatorsQuery, EvaluatorsQueryVariables>) {
        return Apollo.useQuery<EvaluatorsQuery, EvaluatorsQueryVariables>(EvaluatorsDocument, baseOptions);
      }
export function useEvaluatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EvaluatorsQuery, EvaluatorsQueryVariables>) {
          return Apollo.useLazyQuery<EvaluatorsQuery, EvaluatorsQueryVariables>(EvaluatorsDocument, baseOptions);
        }
export type EvaluatorsQueryHookResult = ReturnType<typeof useEvaluatorsQuery>;
export type EvaluatorsLazyQueryHookResult = ReturnType<typeof useEvaluatorsLazyQuery>;
export type EvaluatorsQueryResult = Apollo.QueryResult<EvaluatorsQuery, EvaluatorsQueryVariables>;
export const CreateTeamDocument = gql`
    mutation CreateTeam($input: TeamInput) {
  createTeam(input: $input) {
    teTeam {
      name
      applications {
        id
        applicationName
      }
      evaluatorSet {
        evaluatorTypeDisplay
        evaluator {
          id
        }
      }
    }
  }
}
    `;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, baseOptions);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const CreateClarificationQuestionDocument = gql`
    mutation CreateClarificationQuestion($input: [CreateClarificationQuestionInput]!) {
  createClarificationQuestions(input: $input) {
    errors {
      field
      messages
    }
    clarificationQuestions {
      id
      body
      answerText
      datePublished
      dateSubmitted
      answer {
        id
        evalStatus
      }
    }
  }
}
    `;
export type CreateClarificationQuestionMutationFn = Apollo.MutationFunction<CreateClarificationQuestionMutation, CreateClarificationQuestionMutationVariables>;

/**
 * __useCreateClarificationQuestionMutation__
 *
 * To run a mutation, you first call `useCreateClarificationQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClarificationQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClarificationQuestionMutation, { data, loading, error }] = useCreateClarificationQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClarificationQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateClarificationQuestionMutation, CreateClarificationQuestionMutationVariables>) {
        return Apollo.useMutation<CreateClarificationQuestionMutation, CreateClarificationQuestionMutationVariables>(CreateClarificationQuestionDocument, baseOptions);
      }
export type CreateClarificationQuestionMutationHookResult = ReturnType<typeof useCreateClarificationQuestionMutation>;
export type CreateClarificationQuestionMutationResult = Apollo.MutationResult<CreateClarificationQuestionMutation>;
export type CreateClarificationQuestionMutationOptions = Apollo.BaseMutationOptions<CreateClarificationQuestionMutation, CreateClarificationQuestionMutationVariables>;
export const ClarificationQuestionsDocument = gql`
    query ClarificationQuestions($qid: String) {
  clarificationQuestion(qid: $qid) {
    id
    datePublished
    dateSubmitted
    body
    answerText
    answer {
      id
      evalStatus
    }
  }
}
    `;

/**
 * __useClarificationQuestionsQuery__
 *
 * To run a query within a React component, call `useClarificationQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClarificationQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClarificationQuestionsQuery({
 *   variables: {
 *      qid: // value for 'qid'
 *   },
 * });
 */
export function useClarificationQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<ClarificationQuestionsQuery, ClarificationQuestionsQueryVariables>) {
        return Apollo.useQuery<ClarificationQuestionsQuery, ClarificationQuestionsQueryVariables>(ClarificationQuestionsDocument, baseOptions);
      }
export function useClarificationQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClarificationQuestionsQuery, ClarificationQuestionsQueryVariables>) {
          return Apollo.useLazyQuery<ClarificationQuestionsQuery, ClarificationQuestionsQueryVariables>(ClarificationQuestionsDocument, baseOptions);
        }
export type ClarificationQuestionsQueryHookResult = ReturnType<typeof useClarificationQuestionsQuery>;
export type ClarificationQuestionsLazyQueryHookResult = ReturnType<typeof useClarificationQuestionsLazyQuery>;
export type ClarificationQuestionsQueryResult = Apollo.QueryResult<ClarificationQuestionsQuery, ClarificationQuestionsQueryVariables>;
export const ClarificationListDocument = gql`
    query ClarificationList($uuid: String, $isModeration: Boolean) {
  clarificationQuestions(uuid: $uuid, isModeration: $isModeration) {
    id
    body
    answerText
    answer {
      id
      value
      values
      question {
        id
        order
        category {
          id
          order
          section {
            id
            order
          }
        }
      }
    }
  }
}
    `;

/**
 * __useClarificationListQuery__
 *
 * To run a query within a React component, call `useClarificationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useClarificationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClarificationListQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      isModeration: // value for 'isModeration'
 *   },
 * });
 */
export function useClarificationListQuery(baseOptions?: Apollo.QueryHookOptions<ClarificationListQuery, ClarificationListQueryVariables>) {
        return Apollo.useQuery<ClarificationListQuery, ClarificationListQueryVariables>(ClarificationListDocument, baseOptions);
      }
export function useClarificationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClarificationListQuery, ClarificationListQueryVariables>) {
          return Apollo.useLazyQuery<ClarificationListQuery, ClarificationListQueryVariables>(ClarificationListDocument, baseOptions);
        }
export type ClarificationListQueryHookResult = ReturnType<typeof useClarificationListQuery>;
export type ClarificationListLazyQueryHookResult = ReturnType<typeof useClarificationListLazyQuery>;
export type ClarificationListQueryResult = Apollo.QueryResult<ClarificationListQuery, ClarificationListQueryVariables>;
export const PublishClarificationQuestionsDocument = gql`
    mutation PublishClarificationQuestions($ids: [ID]!) {
  publishClarificationQuestions(ids: $ids) {
    clarificationQuestions {
      body
      id
      datePublished
      answer {
        id
      }
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type PublishClarificationQuestionsMutationFn = Apollo.MutationFunction<PublishClarificationQuestionsMutation, PublishClarificationQuestionsMutationVariables>;

/**
 * __usePublishClarificationQuestionsMutation__
 *
 * To run a mutation, you first call `usePublishClarificationQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishClarificationQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishClarificationQuestionsMutation, { data, loading, error }] = usePublishClarificationQuestionsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function usePublishClarificationQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<PublishClarificationQuestionsMutation, PublishClarificationQuestionsMutationVariables>) {
        return Apollo.useMutation<PublishClarificationQuestionsMutation, PublishClarificationQuestionsMutationVariables>(PublishClarificationQuestionsDocument, baseOptions);
      }
export type PublishClarificationQuestionsMutationHookResult = ReturnType<typeof usePublishClarificationQuestionsMutation>;
export type PublishClarificationQuestionsMutationResult = Apollo.MutationResult<PublishClarificationQuestionsMutation>;
export type PublishClarificationQuestionsMutationOptions = Apollo.BaseMutationOptions<PublishClarificationQuestionsMutation, PublishClarificationQuestionsMutationVariables>;
export const SubmitClarificationQuestionsDocument = gql`
    mutation SubmitClarificationQuestions($uuid: String!) {
  submitClarificationQuestions(uuid: $uuid) {
    submittedClarificationQuestions {
      id
      body
      answerText
      datePublished
      dateSubmitted
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type SubmitClarificationQuestionsMutationFn = Apollo.MutationFunction<SubmitClarificationQuestionsMutation, SubmitClarificationQuestionsMutationVariables>;

/**
 * __useSubmitClarificationQuestionsMutation__
 *
 * To run a mutation, you first call `useSubmitClarificationQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitClarificationQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitClarificationQuestionsMutation, { data, loading, error }] = useSubmitClarificationQuestionsMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSubmitClarificationQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<SubmitClarificationQuestionsMutation, SubmitClarificationQuestionsMutationVariables>) {
        return Apollo.useMutation<SubmitClarificationQuestionsMutation, SubmitClarificationQuestionsMutationVariables>(SubmitClarificationQuestionsDocument, baseOptions);
      }
export type SubmitClarificationQuestionsMutationHookResult = ReturnType<typeof useSubmitClarificationQuestionsMutation>;
export type SubmitClarificationQuestionsMutationResult = Apollo.MutationResult<SubmitClarificationQuestionsMutation>;
export type SubmitClarificationQuestionsMutationOptions = Apollo.BaseMutationOptions<SubmitClarificationQuestionsMutation, SubmitClarificationQuestionsMutationVariables>;
export const SetStatusDocument = gql`
    mutation SetStatus($input: [SetEvalStatusInput]) {
  setEvalStatus(input: $input) {
    answer {
      clarificationQuestions {
        datePublished
        dateSubmitted
        body
        answerText
        id
      }
      id
      evalStatus
    }
  }
}
    `;
export type SetStatusMutationFn = Apollo.MutationFunction<SetStatusMutation, SetStatusMutationVariables>;

/**
 * __useSetStatusMutation__
 *
 * To run a mutation, you first call `useSetStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStatusMutation, { data, loading, error }] = useSetStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetStatusMutation, SetStatusMutationVariables>) {
        return Apollo.useMutation<SetStatusMutation, SetStatusMutationVariables>(SetStatusDocument, baseOptions);
      }
export type SetStatusMutationHookResult = ReturnType<typeof useSetStatusMutation>;
export type SetStatusMutationResult = Apollo.MutationResult<SetStatusMutation>;
export type SetStatusMutationOptions = Apollo.BaseMutationOptions<SetStatusMutation, SetStatusMutationVariables>;
export const ModerationDocument = gql`
    mutation Moderation($input: [ModerationInput]!) {
  moderation(input: $input) {
    answers {
      id
      moderationOutcome
      moderationComment
    }
  }
}
    `;
export type ModerationMutationFn = Apollo.MutationFunction<ModerationMutation, ModerationMutationVariables>;

/**
 * __useModerationMutation__
 *
 * To run a mutation, you first call `useModerationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModerationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moderationMutation, { data, loading, error }] = useModerationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModerationMutation(baseOptions?: Apollo.MutationHookOptions<ModerationMutation, ModerationMutationVariables>) {
        return Apollo.useMutation<ModerationMutation, ModerationMutationVariables>(ModerationDocument, baseOptions);
      }
export type ModerationMutationHookResult = ReturnType<typeof useModerationMutation>;
export type ModerationMutationResult = Apollo.MutationResult<ModerationMutation>;
export type ModerationMutationOptions = Apollo.BaseMutationOptions<ModerationMutation, ModerationMutationVariables>;
export const MobilisationFormDocument = gql`
    query MobilisationForm($uuid: ID) {
  mobilisation2021(uuid: $uuid) {
    id
    name
    preventLead
    representativeName
    representativeAddress
    representativeEmail
    representativeTelephone
    representativeRole
    nominatedIndividual
    informationGovernanceLead
    informationRiskOwner
    caldicottGuardian
    companyCharityNumber
    accountableEmergencyOfficer
    safeguardingLead
    mentalAndDolLead
    freedomToSpeakUpGuardian
    dataProtectionOfficer
    subcontractingDetails
    signatoryName
    signatoryJobTitle
    abuseLead
    registeredOfficeAddress
    employersLiability
    publicLiability
    professionalIdemnity
    serviceAgreement
    providerdomcareSet {
      id
      name
      contractName
      cqcRegistration {
        odsCode
        recordedId
      }
    }
    dateCompleted
  }
}
    `;

/**
 * __useMobilisationFormQuery__
 *
 * To run a query within a React component, call `useMobilisationFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useMobilisationFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMobilisationFormQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMobilisationFormQuery(baseOptions?: Apollo.QueryHookOptions<MobilisationFormQuery, MobilisationFormQueryVariables>) {
        return Apollo.useQuery<MobilisationFormQuery, MobilisationFormQueryVariables>(MobilisationFormDocument, baseOptions);
      }
export function useMobilisationFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MobilisationFormQuery, MobilisationFormQueryVariables>) {
          return Apollo.useLazyQuery<MobilisationFormQuery, MobilisationFormQueryVariables>(MobilisationFormDocument, baseOptions);
        }
export type MobilisationFormQueryHookResult = ReturnType<typeof useMobilisationFormQuery>;
export type MobilisationFormLazyQueryHookResult = ReturnType<typeof useMobilisationFormLazyQuery>;
export type MobilisationFormQueryResult = Apollo.QueryResult<MobilisationFormQuery, MobilisationFormQueryVariables>;
export const UpdateLegalEntityDocument = gql`
    mutation UpdateLegalEntity($input: UpdateLegalEntityInput!) {
  updateLegalEntity(input: $input) {
    id
    name
    preventLead
    representativeName
    representativeAddress
    representativeEmail
    representativeTelephone
    representativeRole
    registeredOfficeAddress
    nominatedIndividual
    informationGovernanceLead
    informationRiskOwner
    accountableEmergencyOfficer
    safeguardingLead
    mentalAndDolLead
    freedomToSpeakUpGuardian
    dataProtectionOfficer
    subcontractingDetails
    signatoryName
    caldicottGuardian
    abuseLead
    errors {
      field
      messages
    }
  }
}
    `;
export type UpdateLegalEntityMutationFn = Apollo.MutationFunction<UpdateLegalEntityMutation, UpdateLegalEntityMutationVariables>;

/**
 * __useUpdateLegalEntityMutation__
 *
 * To run a mutation, you first call `useUpdateLegalEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLegalEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLegalEntityMutation, { data, loading, error }] = useUpdateLegalEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLegalEntityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLegalEntityMutation, UpdateLegalEntityMutationVariables>) {
        return Apollo.useMutation<UpdateLegalEntityMutation, UpdateLegalEntityMutationVariables>(UpdateLegalEntityDocument, baseOptions);
      }
export type UpdateLegalEntityMutationHookResult = ReturnType<typeof useUpdateLegalEntityMutation>;
export type UpdateLegalEntityMutationResult = Apollo.MutationResult<UpdateLegalEntityMutation>;
export type UpdateLegalEntityMutationOptions = Apollo.BaseMutationOptions<UpdateLegalEntityMutation, UpdateLegalEntityMutationVariables>;
export const SetOutcomeLetterStatusDocument = gql`
    mutation SetOutcomeLetterStatus($uuid: String, $status: String) {
  setOutcomeLetterStatus(uuid: $uuid, status: $status) {
    questionnaire {
      uuid
      id
      outcomeLetterStatus
    }
  }
}
    `;
export type SetOutcomeLetterStatusMutationFn = Apollo.MutationFunction<SetOutcomeLetterStatusMutation, SetOutcomeLetterStatusMutationVariables>;

/**
 * __useSetOutcomeLetterStatusMutation__
 *
 * To run a mutation, you first call `useSetOutcomeLetterStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOutcomeLetterStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOutcomeLetterStatusMutation, { data, loading, error }] = useSetOutcomeLetterStatusMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetOutcomeLetterStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetOutcomeLetterStatusMutation, SetOutcomeLetterStatusMutationVariables>) {
        return Apollo.useMutation<SetOutcomeLetterStatusMutation, SetOutcomeLetterStatusMutationVariables>(SetOutcomeLetterStatusDocument, baseOptions);
      }
export type SetOutcomeLetterStatusMutationHookResult = ReturnType<typeof useSetOutcomeLetterStatusMutation>;
export type SetOutcomeLetterStatusMutationResult = Apollo.MutationResult<SetOutcomeLetterStatusMutation>;
export type SetOutcomeLetterStatusMutationOptions = Apollo.BaseMutationOptions<SetOutcomeLetterStatusMutation, SetOutcomeLetterStatusMutationVariables>;
export const AqpSpecialismsDocument = gql`
    query AQPSpecialisms {
  specialisms {
    name
  }
}
    `;

/**
 * __useAqpSpecialismsQuery__
 *
 * To run a query within a React component, call `useAqpSpecialismsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAqpSpecialismsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAqpSpecialismsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAqpSpecialismsQuery(baseOptions?: Apollo.QueryHookOptions<AqpSpecialismsQuery, AqpSpecialismsQueryVariables>) {
        return Apollo.useQuery<AqpSpecialismsQuery, AqpSpecialismsQueryVariables>(AqpSpecialismsDocument, baseOptions);
      }
export function useAqpSpecialismsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AqpSpecialismsQuery, AqpSpecialismsQueryVariables>) {
          return Apollo.useLazyQuery<AqpSpecialismsQuery, AqpSpecialismsQueryVariables>(AqpSpecialismsDocument, baseOptions);
        }
export type AqpSpecialismsQueryHookResult = ReturnType<typeof useAqpSpecialismsQuery>;
export type AqpSpecialismsLazyQueryHookResult = ReturnType<typeof useAqpSpecialismsLazyQuery>;
export type AqpSpecialismsQueryResult = Apollo.QueryResult<AqpSpecialismsQuery, AqpSpecialismsQueryVariables>;
export const AreasDocument = gql`
    query Areas($inLondon: Boolean) {
  areas(inLondon: $inLondon) {
    name
    fullName
    ccgSet {
      name
      localauthoritySet {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useAreasQuery__
 *
 * To run a query within a React component, call `useAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreasQuery({
 *   variables: {
 *      inLondon: // value for 'inLondon'
 *   },
 * });
 */
export function useAreasQuery(baseOptions?: Apollo.QueryHookOptions<AreasQuery, AreasQueryVariables>) {
        return Apollo.useQuery<AreasQuery, AreasQueryVariables>(AreasDocument, baseOptions);
      }
export function useAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AreasQuery, AreasQueryVariables>) {
          return Apollo.useLazyQuery<AreasQuery, AreasQueryVariables>(AreasDocument, baseOptions);
        }
export type AreasQueryHookResult = ReturnType<typeof useAreasQuery>;
export type AreasLazyQueryHookResult = ReturnType<typeof useAreasLazyQuery>;
export type AreasQueryResult = Apollo.QueryResult<AreasQuery, AreasQueryVariables>;
export const ServiceLocationsDocument = gql`
    query ServiceLocations($legalEntityId: ID!) {
  serviceLocationMobilisations(legalEntityId: $legalEntityId) {
    id
    serviceLocation {
      id
      name
      address
    }
    careTiersMobilisation {
      id
      name
    }
    liveInMobilisation
    areasServedMobilisation {
      id
      name
    }
    specialismsMobilisation {
      id
      name
    }
    languagesMobilisation {
      id
      name
    }
    managerFirstNameMobilisation
    managerLastNameMobilisation
    jobTitle
    emailsMobilisation {
      id
      address
    }
    phoneNumbersMobilisation {
      id
      phoneNumber
    }
    aqpStatusMobilisation
  }
}
    `;

/**
 * __useServiceLocationsQuery__
 *
 * To run a query within a React component, call `useServiceLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceLocationsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useServiceLocationsQuery(baseOptions?: Apollo.QueryHookOptions<ServiceLocationsQuery, ServiceLocationsQueryVariables>) {
        return Apollo.useQuery<ServiceLocationsQuery, ServiceLocationsQueryVariables>(ServiceLocationsDocument, baseOptions);
      }
export function useServiceLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceLocationsQuery, ServiceLocationsQueryVariables>) {
          return Apollo.useLazyQuery<ServiceLocationsQuery, ServiceLocationsQueryVariables>(ServiceLocationsDocument, baseOptions);
        }
export type ServiceLocationsQueryHookResult = ReturnType<typeof useServiceLocationsQuery>;
export type ServiceLocationsLazyQueryHookResult = ReturnType<typeof useServiceLocationsLazyQuery>;
export type ServiceLocationsQueryResult = Apollo.QueryResult<ServiceLocationsQuery, ServiceLocationsQueryVariables>;
export const LanguagesDocument = gql`
    query Languages {
  languages {
    name
    code
  }
}
    `;

/**
 * __useLanguagesQuery__
 *
 * To run a query within a React component, call `useLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
        return Apollo.useQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, baseOptions);
      }
export function useLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
          return Apollo.useLazyQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, baseOptions);
        }
export type LanguagesQueryHookResult = ReturnType<typeof useLanguagesQuery>;
export type LanguagesLazyQueryHookResult = ReturnType<typeof useLanguagesLazyQuery>;
export type LanguagesQueryResult = Apollo.QueryResult<LanguagesQuery, LanguagesQueryVariables>;
export const SubmitMobilisationFormDocument = gql`
    mutation SubmitMobilisationForm($uuid: ID!) {
  submitMobilisationForm(uuid: $uuid) {
    errors {
      field
      messages
    }
    entity {
      uuid
      dateCompleted
    }
  }
}
    `;
export type SubmitMobilisationFormMutationFn = Apollo.MutationFunction<SubmitMobilisationFormMutation, SubmitMobilisationFormMutationVariables>;

/**
 * __useSubmitMobilisationFormMutation__
 *
 * To run a mutation, you first call `useSubmitMobilisationFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitMobilisationFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitMobilisationFormMutation, { data, loading, error }] = useSubmitMobilisationFormMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSubmitMobilisationFormMutation(baseOptions?: Apollo.MutationHookOptions<SubmitMobilisationFormMutation, SubmitMobilisationFormMutationVariables>) {
        return Apollo.useMutation<SubmitMobilisationFormMutation, SubmitMobilisationFormMutationVariables>(SubmitMobilisationFormDocument, baseOptions);
      }
export type SubmitMobilisationFormMutationHookResult = ReturnType<typeof useSubmitMobilisationFormMutation>;
export type SubmitMobilisationFormMutationResult = Apollo.MutationResult<SubmitMobilisationFormMutation>;
export type SubmitMobilisationFormMutationOptions = Apollo.BaseMutationOptions<SubmitMobilisationFormMutation, SubmitMobilisationFormMutationVariables>;
export const UpdateAqpMobilisationDocument = gql`
    mutation UpdateAqpMobilisation($input: UpdateServiceLocationInput) {
  updateAqpDataMobilisation(input: $input) {
    serviceLocationMobilisation {
      careTiersMobilisation {
        name
      }
      liveInMobilisation
      areasServedMobilisation {
        name
      }
      specialismsMobilisation {
        name
      }
      languagesMobilisation {
        name
      }
      managerFirstNameMobilisation
      managerLastNameMobilisation
      jobTitle
      emailsMobilisation {
        address
      }
      phoneNumbersMobilisation {
        phoneNumber
      }
    }
  }
}
    `;
export type UpdateAqpMobilisationMutationFn = Apollo.MutationFunction<UpdateAqpMobilisationMutation, UpdateAqpMobilisationMutationVariables>;

/**
 * __useUpdateAqpMobilisationMutation__
 *
 * To run a mutation, you first call `useUpdateAqpMobilisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAqpMobilisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAqpMobilisationMutation, { data, loading, error }] = useUpdateAqpMobilisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAqpMobilisationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAqpMobilisationMutation, UpdateAqpMobilisationMutationVariables>) {
        return Apollo.useMutation<UpdateAqpMobilisationMutation, UpdateAqpMobilisationMutationVariables>(UpdateAqpMobilisationDocument, baseOptions);
      }
export type UpdateAqpMobilisationMutationHookResult = ReturnType<typeof useUpdateAqpMobilisationMutation>;
export type UpdateAqpMobilisationMutationResult = Apollo.MutationResult<UpdateAqpMobilisationMutation>;
export type UpdateAqpMobilisationMutationOptions = Apollo.BaseMutationOptions<UpdateAqpMobilisationMutation, UpdateAqpMobilisationMutationVariables>;
export const UpdateAqpStatusDocument = gql`
    mutation UpdateAqpStatus($serviceLocationId: ID!, $status: Boolean!) {
  updateAqpStatusMobilisation(
    serviceLocationId: $serviceLocationId
    status: $status
  ) {
    serviceLocationMobilisation {
      aqpStatus
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type UpdateAqpStatusMutationFn = Apollo.MutationFunction<UpdateAqpStatusMutation, UpdateAqpStatusMutationVariables>;

/**
 * __useUpdateAqpStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAqpStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAqpStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAqpStatusMutation, { data, loading, error }] = useUpdateAqpStatusMutation({
 *   variables: {
 *      serviceLocationId: // value for 'serviceLocationId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateAqpStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAqpStatusMutation, UpdateAqpStatusMutationVariables>) {
        return Apollo.useMutation<UpdateAqpStatusMutation, UpdateAqpStatusMutationVariables>(UpdateAqpStatusDocument, baseOptions);
      }
export type UpdateAqpStatusMutationHookResult = ReturnType<typeof useUpdateAqpStatusMutation>;
export type UpdateAqpStatusMutationResult = Apollo.MutationResult<UpdateAqpStatusMutation>;
export type UpdateAqpStatusMutationOptions = Apollo.BaseMutationOptions<UpdateAqpStatusMutation, UpdateAqpStatusMutationVariables>;
export const GenerateContractDocument = gql`
    mutation GenerateContract($uuid: String!, $contractGenerated: Boolean!) {
  generateContract(uuid: $uuid, contractGenerated: $contractGenerated) {
    questionnaire {
      id
      uuid
      contractGenerated
    }
  }
}
    `;
export type GenerateContractMutationFn = Apollo.MutationFunction<GenerateContractMutation, GenerateContractMutationVariables>;

/**
 * __useGenerateContractMutation__
 *
 * To run a mutation, you first call `useGenerateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateContractMutation, { data, loading, error }] = useGenerateContractMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      contractGenerated: // value for 'contractGenerated'
 *   },
 * });
 */
export function useGenerateContractMutation(baseOptions?: Apollo.MutationHookOptions<GenerateContractMutation, GenerateContractMutationVariables>) {
        return Apollo.useMutation<GenerateContractMutation, GenerateContractMutationVariables>(GenerateContractDocument, baseOptions);
      }
export type GenerateContractMutationHookResult = ReturnType<typeof useGenerateContractMutation>;
export type GenerateContractMutationResult = Apollo.MutationResult<GenerateContractMutation>;
export type GenerateContractMutationOptions = Apollo.BaseMutationOptions<GenerateContractMutation, GenerateContractMutationVariables>;
export const IssueContractDocument = gql`
    mutation IssueContract($uuid: String!, $contractIssued: Boolean!) {
  issueContract(uuid: $uuid, contractIssued: $contractIssued) {
    questionnaire {
      id
      uuid
      contractIssued
    }
  }
}
    `;
export type IssueContractMutationFn = Apollo.MutationFunction<IssueContractMutation, IssueContractMutationVariables>;

/**
 * __useIssueContractMutation__
 *
 * To run a mutation, you first call `useIssueContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueContractMutation, { data, loading, error }] = useIssueContractMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      contractIssued: // value for 'contractIssued'
 *   },
 * });
 */
export function useIssueContractMutation(baseOptions?: Apollo.MutationHookOptions<IssueContractMutation, IssueContractMutationVariables>) {
        return Apollo.useMutation<IssueContractMutation, IssueContractMutationVariables>(IssueContractDocument, baseOptions);
      }
export type IssueContractMutationHookResult = ReturnType<typeof useIssueContractMutation>;
export type IssueContractMutationResult = Apollo.MutationResult<IssueContractMutation>;
export type IssueContractMutationOptions = Apollo.BaseMutationOptions<IssueContractMutation, IssueContractMutationVariables>;
export const IssueNotEmbeddedContractDocument = gql`
    mutation IssueNotEmbeddedContract($uuid: String!, $contractIssuedNotEmbedded: Boolean!) {
  issueNotEmbeddedContract(
    uuid: $uuid
    contractIssuedNotEmbedded: $contractIssuedNotEmbedded
  ) {
    questionnaire {
      id
      uuid
      contractIssuedNotEmbedded
    }
  }
}
    `;
export type IssueNotEmbeddedContractMutationFn = Apollo.MutationFunction<IssueNotEmbeddedContractMutation, IssueNotEmbeddedContractMutationVariables>;

/**
 * __useIssueNotEmbeddedContractMutation__
 *
 * To run a mutation, you first call `useIssueNotEmbeddedContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueNotEmbeddedContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueNotEmbeddedContractMutation, { data, loading, error }] = useIssueNotEmbeddedContractMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      contractIssuedNotEmbedded: // value for 'contractIssuedNotEmbedded'
 *   },
 * });
 */
export function useIssueNotEmbeddedContractMutation(baseOptions?: Apollo.MutationHookOptions<IssueNotEmbeddedContractMutation, IssueNotEmbeddedContractMutationVariables>) {
        return Apollo.useMutation<IssueNotEmbeddedContractMutation, IssueNotEmbeddedContractMutationVariables>(IssueNotEmbeddedContractDocument, baseOptions);
      }
export type IssueNotEmbeddedContractMutationHookResult = ReturnType<typeof useIssueNotEmbeddedContractMutation>;
export type IssueNotEmbeddedContractMutationResult = Apollo.MutationResult<IssueNotEmbeddedContractMutation>;
export type IssueNotEmbeddedContractMutationOptions = Apollo.BaseMutationOptions<IssueNotEmbeddedContractMutation, IssueNotEmbeddedContractMutationVariables>;
export const SignContractDocument = gql`
    mutation SignContract($uuid: String!, $contractSigned: Boolean!) {
  signContract(uuid: $uuid, contractSigned: $contractSigned) {
    questionnaire {
      id
      uuid
      contractSigned
    }
  }
}
    `;
export type SignContractMutationFn = Apollo.MutationFunction<SignContractMutation, SignContractMutationVariables>;

/**
 * __useSignContractMutation__
 *
 * To run a mutation, you first call `useSignContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signContractMutation, { data, loading, error }] = useSignContractMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      contractSigned: // value for 'contractSigned'
 *   },
 * });
 */
export function useSignContractMutation(baseOptions?: Apollo.MutationHookOptions<SignContractMutation, SignContractMutationVariables>) {
        return Apollo.useMutation<SignContractMutation, SignContractMutationVariables>(SignContractDocument, baseOptions);
      }
export type SignContractMutationHookResult = ReturnType<typeof useSignContractMutation>;
export type SignContractMutationResult = Apollo.MutationResult<SignContractMutation>;
export type SignContractMutationOptions = Apollo.BaseMutationOptions<SignContractMutation, SignContractMutationVariables>;
export const UpdateSignatureRequestDocument = gql`
    mutation UpdateSignatureRequest($uuid: String!, $signatureId: String!, $signatureRequestId: String!) {
  updateSignatureRequest(
    uuid: $uuid
    signatureId: $signatureId
    signatureRequestId: $signatureRequestId
  ) {
    questionnaire {
      id
      uuid
      signatureId
      signatureRequestId
    }
  }
}
    `;
export type UpdateSignatureRequestMutationFn = Apollo.MutationFunction<UpdateSignatureRequestMutation, UpdateSignatureRequestMutationVariables>;

/**
 * __useUpdateSignatureRequestMutation__
 *
 * To run a mutation, you first call `useUpdateSignatureRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSignatureRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSignatureRequestMutation, { data, loading, error }] = useUpdateSignatureRequestMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      signatureId: // value for 'signatureId'
 *      signatureRequestId: // value for 'signatureRequestId'
 *   },
 * });
 */
export function useUpdateSignatureRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSignatureRequestMutation, UpdateSignatureRequestMutationVariables>) {
        return Apollo.useMutation<UpdateSignatureRequestMutation, UpdateSignatureRequestMutationVariables>(UpdateSignatureRequestDocument, baseOptions);
      }
export type UpdateSignatureRequestMutationHookResult = ReturnType<typeof useUpdateSignatureRequestMutation>;
export type UpdateSignatureRequestMutationResult = Apollo.MutationResult<UpdateSignatureRequestMutation>;
export type UpdateSignatureRequestMutationOptions = Apollo.BaseMutationOptions<UpdateSignatureRequestMutation, UpdateSignatureRequestMutationVariables>;
export const SignContractsDocument = gql`
    mutation SignContracts($signatureRequestIds: [String]!) {
  signContracts(signatureRequestIds: $signatureRequestIds) {
    questionnaires {
      id
      uuid
      contractSigned
    }
  }
}
    `;
export type SignContractsMutationFn = Apollo.MutationFunction<SignContractsMutation, SignContractsMutationVariables>;

/**
 * __useSignContractsMutation__
 *
 * To run a mutation, you first call `useSignContractsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignContractsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signContractsMutation, { data, loading, error }] = useSignContractsMutation({
 *   variables: {
 *      signatureRequestIds: // value for 'signatureRequestIds'
 *   },
 * });
 */
export function useSignContractsMutation(baseOptions?: Apollo.MutationHookOptions<SignContractsMutation, SignContractsMutationVariables>) {
        return Apollo.useMutation<SignContractsMutation, SignContractsMutationVariables>(SignContractsDocument, baseOptions);
      }
export type SignContractsMutationHookResult = ReturnType<typeof useSignContractsMutation>;
export type SignContractsMutationResult = Apollo.MutationResult<SignContractsMutation>;
export type SignContractsMutationOptions = Apollo.BaseMutationOptions<SignContractsMutation, SignContractsMutationVariables>;
export const IssueLettersDocument = gql`
    mutation IssueLetters {
  issueLetters {
    issued
  }
}
    `;
export type IssueLettersMutationFn = Apollo.MutationFunction<IssueLettersMutation, IssueLettersMutationVariables>;

/**
 * __useIssueLettersMutation__
 *
 * To run a mutation, you first call `useIssueLettersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueLettersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueLettersMutation, { data, loading, error }] = useIssueLettersMutation({
 *   variables: {
 *   },
 * });
 */
export function useIssueLettersMutation(baseOptions?: Apollo.MutationHookOptions<IssueLettersMutation, IssueLettersMutationVariables>) {
        return Apollo.useMutation<IssueLettersMutation, IssueLettersMutationVariables>(IssueLettersDocument, baseOptions);
      }
export type IssueLettersMutationHookResult = ReturnType<typeof useIssueLettersMutation>;
export type IssueLettersMutationResult = Apollo.MutationResult<IssueLettersMutation>;
export type IssueLettersMutationOptions = Apollo.BaseMutationOptions<IssueLettersMutation, IssueLettersMutationVariables>;
export const LettersIssuedDocument = gql`
    query LettersIssued {
  lettersIssuedInfo {
    lettersIssued
    lettersIssuedDate
  }
}
    `;

/**
 * __useLettersIssuedQuery__
 *
 * To run a query within a React component, call `useLettersIssuedQuery` and pass it any options that fit your needs.
 * When your component renders, `useLettersIssuedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLettersIssuedQuery({
 *   variables: {
 *   },
 * });
 */
export function useLettersIssuedQuery(baseOptions?: Apollo.QueryHookOptions<LettersIssuedQuery, LettersIssuedQueryVariables>) {
        return Apollo.useQuery<LettersIssuedQuery, LettersIssuedQueryVariables>(LettersIssuedDocument, baseOptions);
      }
export function useLettersIssuedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LettersIssuedQuery, LettersIssuedQueryVariables>) {
          return Apollo.useLazyQuery<LettersIssuedQuery, LettersIssuedQueryVariables>(LettersIssuedDocument, baseOptions);
        }
export type LettersIssuedQueryHookResult = ReturnType<typeof useLettersIssuedQuery>;
export type LettersIssuedLazyQueryHookResult = ReturnType<typeof useLettersIssuedLazyQuery>;
export type LettersIssuedQueryResult = Apollo.QueryResult<LettersIssuedQuery, LettersIssuedQueryVariables>;
export const CommissionerContactInformationDocument = gql`
    query CommissionerContactInformation {
  commissionersContactInformation {
    signerNumber
    name
    emailAddress
  }
}
    `;

/**
 * __useCommissionerContactInformationQuery__
 *
 * To run a query within a React component, call `useCommissionerContactInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommissionerContactInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommissionerContactInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommissionerContactInformationQuery(baseOptions?: Apollo.QueryHookOptions<CommissionerContactInformationQuery, CommissionerContactInformationQueryVariables>) {
        return Apollo.useQuery<CommissionerContactInformationQuery, CommissionerContactInformationQueryVariables>(CommissionerContactInformationDocument, baseOptions);
      }
export function useCommissionerContactInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommissionerContactInformationQuery, CommissionerContactInformationQueryVariables>) {
          return Apollo.useLazyQuery<CommissionerContactInformationQuery, CommissionerContactInformationQueryVariables>(CommissionerContactInformationDocument, baseOptions);
        }
export type CommissionerContactInformationQueryHookResult = ReturnType<typeof useCommissionerContactInformationQuery>;
export type CommissionerContactInformationLazyQueryHookResult = ReturnType<typeof useCommissionerContactInformationLazyQuery>;
export type CommissionerContactInformationQueryResult = Apollo.QueryResult<CommissionerContactInformationQuery, CommissionerContactInformationQueryVariables>;